import React, { useState } from 'react';
import Button from '../buttons/Button';
import styles from './WaveCopy.module.css';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown';

/**
 * @component WaveCopyPopup
 * @param {Function} handleClose - A callback function to close the popup.
 * @param {Array} waves - An array of available waves to choose from.
 * @param {Function} getSourceWaveId - A callback function to get the selected source wave ID.
 *
 * @description A component for copying waves that allows the user to choose which wave they can copy, depending on the wave type.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleClose = (action) => {
 *   console.log(`Popup closed with action: ${action}`);
 * };
 *
 * const waves = [
 *   { name: 'Wave 1' },
 *   { name: 'Wave 2' },
 *   { name: 'Wave 3' }
 * ];
 *
 * const getSourceWaveId = (val) => {
 *   console.log(`Selected wave ID: ${val}`);
 * };
 *
 * return (
 *   <WaveCopyPopup
 *     handleClose={handleClose}
 *     waves={waves}
 *     getSourceWaveId={getSourceWaveId}
 *   />
 * );
 */
function WaveCopyPopup({ handleClose, waves, getSourceWaveId }) {
  const [showWaveList, setShowWaveList] = useState(false);
  //this should be the list of waves, to come from DB
  const itemList = waves;

  /**
   * @function handleClick
   * @memberof WaveCopyPopup
   * @param {'yes' | 'no' | 'close'} btn - The button action ('yes', 'no', 'close').
   *
   * @description Handles button clicks and controls the popup behavior depending on the button clicked.
   */
  const handleClick = (btn) => {
    if (btn === 'yes' && !showWaveList) {
      setShowWaveList(true);
    } else if (btn === 'yes' && showWaveList) {
      handleClose('copy');
    } else if (btn === 'no' || btn === 'close') {
      handleClose('cancel');
    }
  };

  /**
   * @function handleDropdownInput
   *
   * @param {String} val - The selected value from the dropdown.
   *
   * @description Handles input selection from the searchable dropdown and calls the 'getSourceWaveId' props function with the selected value from the dropdown as the argument.
   */
  const handleDropdownInput = (val) => {
    if (val !== '') {
      getSourceWaveId(val);
    }
  };

  return (
    <div>
      <div className={styles.BtnCloseWrapper}>
        <Button handleOnClick={() => handleClick('close')} type="close" />
      </div>
      <div className={styles.Title}>
        <h4>{showWaveList ? 'Wave list' : 'Copy wave'}</h4>
      </div>
      {itemList.length === 0 ? (
        <div className={styles.Message}>
          Please note, there are currently no available waves to copy. Please
          make your module selection and add local questions.
        </div>
      ) : (
        <>
          <div className={styles.Message}>
            {showWaveList ? (
              <div>
                <SearchableDropdown
                  id={'waveCopySelect'}
                  waveList={itemList}
                  handleInputProp={(val, id) => handleDropdownInput(val)}
                />
              </div>
            ) : (
              <div>
                You are about to copy an existing wave, which will override any
                selections made for this wave.{<br />}Are you sure you want to
                continue?
              </div>
            )}
          </div>
          <div className={styles.BtnWrapper}>
            <Button handleOnClick={() => handleClick('no')} type="secondary">
              {showWaveList ? 'Cancel' : 'No'}
            </Button>
            <Button handleOnClick={() => handleClick('yes')} type="primary">
              {showWaveList ? 'Copy' : 'Yes'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default WaveCopyPopup;
