import axios from '../axiosInstance';
import { saveAs } from 'file-saver';
export const downloadWaveXLSX = (waveId, fileName) => {
    return (dispatch) => {
      dowloadXlsx(`/Waves/${waveId}/QuestionniareDownload`, fileName);        
    };
  };
  export const downloadProjectXLSX = (projectId, countryId, fileName) => {
    return (dispatch) => {
      dowloadXlsx(`/Projects/${projectId}/countries/${countryId}/QuestionniareDownload`,fileName);
    };
  };

  const dowloadXlsx = (url, fileName) => {
    axios
        .get(url, { responseType: 'blob' })
        .then((res) => {         
          if (res.status !== 200) {
            throw new Error(res.statusText);
          }
          const blob = new Blob([res.data]);
          saveAs(blob, `${fileName}.xlsx`);        
        })
        .catch((error) => {
          console.log("Download error", error)
        });
  }