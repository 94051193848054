import React from 'react';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from './ui/breadcrumbs/Breadcrumbs';
import logoutIcon from  "../assets/svg/logout.svg";
import { useSelector } from 'react-redux';
import styles from "../App.module.css";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = ({userName, logout}) => {

  const breadcrumbs = useSelector(
    (state) => state.app.breadcrumbs
  );
  return (
    <>
        <div className={styles.toolbar}>
          <div className={styles.leftToolbar}><Breadcrumbs crumbs={breadcrumbs}></Breadcrumbs></div>
          <div className={styles.user}>{userName}<img className={styles.logout} src={logoutIcon} alt="" onClick={logout} title="Sign Out" /></div>
        </div>
        <div className={styles.body}>
          <Outlet />
        </div>
      </>
  );
};
