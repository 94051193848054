

import React from 'react';
import styles from '../dpInstructionTablePreview/DPInstructionTablePreview.module.css';


const DPInstructionTablePreview = ({ id,item }) => {

    return (
      <div key={id}>
        <div className={`${styles.Wrapper}`}>
          <div className={styles.SelectionMenu}>
            <div>
              <label className={styles.label}>Question Name</label>
              <p id="DPtableName">{item.tableName}</p>
            </div>
            <div className={styles.middleLabel}>
              <label className={styles.label}>Short title</label>
              <p id="DPtableTitle">{item.tableTitle}</p>
            </div>
            <div className={styles.rightLabel}>
              <label className={styles.label}>Base / Filter</label>
              <p id="DPtableBase">{item.tableBase}</p>
            </div>
          </div>

          <div className={styles.SelectionMenu}>
            <div>
              <label className={styles.label}>Instructions</label>
              <p id="DPtableInstructions">{item.dpInstruction}</p>
            </div>
          </div>
          <div className={styles.SelectionMenu}>
            <div>
              <label className={styles.label}>Additional Information</label>
              <p id="DPtableAddlInfo">{item.dpAdditionalInformation}</p>
            </div>
          </div>
        </div>
      </div>
    );
}

export default DPInstructionTablePreview;