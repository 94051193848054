import * as actionTypes from '../actions/actionTypes';

const initialState = {
  messages: {},
  isLoading: false,
};
const messageStart = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const messageFail = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};
const messageSuccess = (state, action) => {
  return {
    ...state,
    messages: { ...action.messages },
    isLoading: false,
  };
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MESSAGE_LIST_START:
      return messageStart(state);
    case actionTypes.FETCH_MESSAGE_LIST_SUCCESS:
      return messageSuccess(state, action);
    case actionTypes.FETCH_MODULES_LIST_FAIL:
      return messageFail(state);
    default:
      return state;
  }
};

export default appReducer;
