import React, {useState} from 'react';
import Button from '../buttons/Button';
import styles from './RejectModuleSelection.module.css';
import { ReactComponent as CancelIcon } from "../../../assets/svg/cancel.svg";
import TextArea from '../TextArea/TextArea';

const RejectModuleSelection = ({ id, handleReject, handleClose }) => {
  const [inputValue, setInputValue] = useState('');
  const onSendNotification = () => {
    handleReject(inputValue);
};
  return (
    <div id = {id}>
      <div className={styles.header}>
      <div className={styles.Title}>
        Reject module selection
      </div>
      <button
           className={styles.Close} onClick={() => handleClose()}
          >
            <CancelIcon height={16} />
          </button>
      </div>
      
      
      <div className={styles.Message}>
        <div>
          Clicking on the "Send notification" button will notify the client that
          the module selection needs to be updated. Please write down any
          additional details in the text field below, and we will add them to
          the email.
        </div>  
        <TextArea
          id = "Id-RejectSelecttion-TextArea"
          placeholder="Please add more details here"
          value={inputValue}
          onChange={(id, value) => setInputValue(value)}
          additionalStyling = {['textArea-fullwidth']}
          required = {false}
        ></TextArea>
      </div>
      <div className={styles.btnContainer}>
        <Button handleOnClick={() => handleClose()} type="secondary">
          Cancel
        </Button>
        <Button
         disabled={inputValue.trim() === ''}
          handleOnClick={() => {
            onSendNotification();
            handleClose();
          }}
          type="primary">
          Send notification
        </Button>
      </div>
    </div>
  );
};

export default RejectModuleSelection;
