import { useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import styles from './SearchableDropdown.module.css';
import PropTypes from 'prop-types';

/**
 * @component SearchableDropdown
 * @param {Array} waveList - An array of items to populate the dropdown list.
 * @param {Function} handleInputProp - A function to handle the selected item from the dropdown.
 * @param {String} id - Identifier for the dropdown component.
 *
 * @description A component that provides a searchable dropdown list with filtering options.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const waveList = [
 *   { name: 'Wave 1' },
 *   { name: 'Wave 2' },
 *   { name: 'Wave 3' }
 * ];
 *
 * const handleInputProp = (value, id) => {
 *   console.log(`Selected value: ${value} from dropdown with id: ${id}`);
 * };
 *
 * return (
 *   <SearchableDropdown
 *     waveList={waveList}
 *     handleInputProp={handleInputProp}
 *     id="dropdown-1"
 *   />
 * );
 */
function SearchableDropdown({ waveList, handleInputProp, id }) {
  const itemList = useRef([]);
  const [showList, setShowList] = useState(false);
  const [validated, setValidated] = useState(true);
  const [hovered, setHovered] = useState(-1);
  const [filterText, setFilterText] = useState('');
  const deferredFilterText = useDeferredValue(filterText);
  const collection = useRef();

  //rerender delay with deferredValue hook
  const deferredList = useMemo(() => {
    return waveList.filter(
      (item, index) =>
        item.name.toLowerCase().indexOf(deferredFilterText.toLowerCase()) !==
          -1 || deferredFilterText.length === 0
    );
  }, [deferredFilterText,waveList]);

  collection.current = deferredList.map((el, index) => {
    return (
      <div
        key={el.name}
        className={`${styles.ListItem} ${
          hovered === index ? styles.Hovered : ''
        }`}
        onClick={(e) => handleSelect(el.name, e)}
        onMouseEnter={() => hoverStart(index)}
        onMouseLeave={() => hoverEnd()}
      >
        <span>{el.name}</span>
      </div>
    );
  });

  /**
   * @function
   * @memberof SearchableDropdown
   * @param {Object} e - The input change event object.
   *
   * @description Handles input changes in the search input field. If 'validated' is true it sets the 'validated' and 'showList' state variables to true and 'hovered' to (-1). After doing that it sets the 'filterText' state variable to the value of the event target.
   */
  const handleInput = (e) => {
    let val = e.target.value;

    if (validated) {
      setValidated(true);
      setShowList(true);
      setHovered(-1);
    }

    setFilterText(val);
  };

  /**
   * @function
   * @memberof SearchableDropdown
   * @param {string} val - The selected item's value.
   * @param {object} e - The click event object.
   * @param {boolean} showlist - Indicates whether to show the dropdown list.
   *
   * @description Filters out the list to show the selected item, passes back the item identifier (hadleInputProp), sets the list visibility based on 'showlist' and marks the input field as valid.
   */
  const handleSelect = (val, e, showlist) => {
    e.stopPropagation();
    handleInputProp(val, id);
    let sh = false;
    if (showlist) {
      sh = true;
    }

    setShowList(sh);
    setValidated(true);
    setFilterText(val);
  };

  /**
   * @function toggleList
   * @memberof SearchableDropdown
   *
   * @description Toggles the visibility of the dropdown list.
   */
  const toggleList = () => {
    let currentListState = showList;
    let filterTxt = filterText;
    if (currentListState) {
      let lowerCaseList = waveList.map((item) => item.name.toLowerCase());
      if (lowerCaseList.indexOf(filterTxt.toLowerCase()) === -1) {
        handleInputProp('', id);
      } else {
        handleInputProp(filterTxt, id);
      }
    }

    setShowList(!currentListState);
    setFilterText(filterTxt);
  };

  /**
   * @function hoverStart
   * @memberof SearchableDropdown
   * @param {number} ind - The index of the hovered item.
   *
   * @description Handles mouse hover over an item in the dropdown list.
   */
  const hoverStart = (ind) => {
    setHovered(ind);
  };

  /**
   * @function hoverStart
   * @memberof SearchableDropdown
   *
   * @description Handles mouse leaving an item in the dropdown list.
   */
  const hoverEnd = () => {
    setHovered(-1);
  };

  /**
   * @function keyPress
   * @param {object} e - The keyboard event object.
   *
   * @description Calls the apropriate functions when the user either presses on 'arrowUp', 'arrowDown' or 'enter'. The function performs the following actions
   *
   * - Updates the hovered item index when using arrow keys.
   * - Resets the hovered item index when using arrow keys.
   * - Selects an item when the Enter key is pressed.
   */
  const keyPress = (e) => {
    if (e.keyCode === 40) {
      //arrowDown
      let hoverInd = hovered;
      if (hoverInd < deferredList.length - 1) {
        hoverInd++;
        setShowList(true);
        setHovered(hoverInd);
      }
    }
    if (e.keyCode === 38) {
      //arrowUp
      let hoverInd = hovered;
      if (hoverInd > 0) {
        hoverInd--;
        setHovered(hoverInd);
      }
    }
    if (e.keyCode === 13) {
      //enter
      let hoverInd = hovered;
      if (hoverInd !== -1) {
        let selected = deferredList[hoverInd];
        handleSelect(selected, e);
      }
    }
  };

  /**
   * @method useEffect
   * @memberof SearchableDropdown
   * @description Populate 'itemList' with names from 'waveList' for filtering.
   */
  useEffect(() => {
    itemList.current = waveList.map((wave) => {
      return wave.name;
    });
  }, [waveList]);

  return (
    <div
      data-testid="id-searchableDropdown"
      className={`${styles.DropDownContainer} ${
        showList ? styles.Expanded : ''
      }`}
      onClick={() => toggleList()}
      onKeyDown={(e) => keyPress(e)}
    >
      <input
        data-testid="id-input"
        type="text"
        className={styles.DropDownSearch}
        value={filterText}
        onChange={handleInput}
      ></input>
      <div
        className={`${styles.ListContainer} ${showList ? '' : styles.Hidden}`}
      >
        {collection.current}
      </div>
    </div>
  );
}

SearchableDropdown.propTypes = {
  waveList: PropTypes.array.isRequired,
};

export default SearchableDropdown;
