import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './AccordionMenu.module.css';

/**
 * @component AccordionMenuItem
 * @param {Number} id - The ID of the accordion menu item.
 * @param {String} name - The name of the accordion menu item.
 * @param {Boolean} expanded - Whether the subitems of the accordion menu item are expanded.
 * @param {Function} onClickItem - Function to handle click on the accordion menu item.
 * @param {Number} currentCountryId - The ID of the current country.
 * @param {Number} scrollTo - The ID of the subitem to scroll to.
 * @description A component that represents an item in the accordion menu.
 *
 * @returns {JSX.Element} The rendered accordion menu item component.
 *
 * @example
 * const onClickItem = (id) => {
 *   console.log(`Accordion item clicked with id: ${id}`);
 * };
 *
 * const currentCountryId = 1;
 * const scrollTo = 102;
 *
 * return (
 *   <AccordionMenuItem
 *     id={1}
 *     name="Country 1"
 *     expanded={true}
 *     onClickItem={onClickItem}
 *     currentCountryId={currentCountryId}
 *     scrollTo={scrollTo}
 *   />
 * );
 */
const AccordionMenuItem = ({
  id,
  name,
  expanded,
  onClickItem,
  currentCountryId,
  scrollTo,
}) => {

    const [showSubitems, ] = useState(expanded);
  const [ref, setRef] = useState(null);
  const refCurrentCountry = useRef(null);

  /**
@method useEffect - 1st instance  
   * @memberof AccordionMenuItem
   *
   * @description When the value of 'currentCountryId' updates the 'ref' state variable is set to the value of the ref 'refCurrentCountry'.
   */
  useEffect(() => {
    setRef(refCurrentCountry);
  }, [currentCountryId]);

  /**
@method useEffect - 2nd instance  
   * @memberof AccordionMenuItem
   *
   * @description When the value of 'ref' updates the effects scrolls to the specified subitem.
   */
  useEffect(() => {
    if (scrollTo !== undefined && scrollTo !== -1 && !isNaN(scrollTo)) {
      // prevents automatic scrolling to the bottom
      // if scrollTo is does not have an acceptable value
      refCurrentCountry.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [ref,scrollTo]);

  return (
    <div
      className={styles.item}
      data-testid={id}
      aria-label={name}
      aria-expanded={showSubitems}
      ref={ref}
    >
      {/* show the header always */}
      <div className={`${styles.header}`}>
        <button
          className={`${styles.btn}`}
          tabIndex={1}
          onClick={() => {
            onClickItem(id);
            return id;
          }}
        >
          <span>{name}</span>
        </button>
      </div>
    </div>
  );
};

AccordionMenuItem.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
};

AccordionMenuItem.defaultProps = {
  id: -1,
  name: 'default',
  expanded: false,
};

export default AccordionMenuItem;
