import React from 'react';
import PropTypes from 'prop-types';
import styles from './LengthOfInterview.module.css';

/**
 * @component LengthOfInterview
 * @param {number} number - The number representing the length of the interview in minutes.
 * @param {number} threshold - An optional threshold number for highlighting the length if it exceeds the threshold.
 * @param {array} children - An array of strings to customize the display text.
 *
 * @description A component for displaying the length of an interview.
 *
 * @returns {JSX.Element}
 *
 * @example
 * return (
 *   <LengthOfInterview
 *     number={25}
 *     threshold={20}
 *     children={['Interview Duration:', 'minutes']}
 *   />
 * );
 */
const LengthOfInterview = ({ number, threshold, children }) => {
  return (
    <h4
      className={
        number > threshold
          ? `${styles.LOI_aboveThreshold} ${styles.LOI}`
          : styles.LOI
      }
    >
      {children[0]} {number} {children[1]}
    </h4>
  );
};

LengthOfInterview.propTypes = {
  number: PropTypes.number.isRequired,
  threshold: PropTypes.number,
  children: PropTypes.array,
};

LengthOfInterview.defaultProps = {
  number: 0,
  threshold: null,
  children: ['Length of interview:', 'minutes'],
};

export default LengthOfInterview;
