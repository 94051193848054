import React from 'react';
import PropTypes from 'prop-types';

import styles from './AccordionDisplay.module.css';
import AccordionDisplayItem from './AccordionDisplayItem';

/**
 * @component AccordionDisplay
 * @param {Object} filteredProjectList - The list of projects to display.
 * @param {String} country - The country name.
 * @param {Array} items - The list of items to display in the accordion.
 * @param {String} currentWaveId - The ID of the current wave.
 * @param {String} onClickModuleSelection - Function to handle click on module selection.
 * @param {Function} onClickEditWave - Function to handle click on edit wave.
 * @param {Boolean} editWavesPrivilege - Indicates if the user has edit waves privilege.
 * @param {Function} statusToStatusType - Function to return a formatted value of the status.
 * @param {Function} onClickModulePreview - Function to handle click on module preview.
 *
 * @description A component that displays an accordion with a list of 'AccordionDisplayItems' components.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const filteredProjectList = [1, 2, 3];
 * const country = 'USA';
 * const items = [
 *   { waveId: 1, name: 'Wave 1', projectId: 1, waveStatus: { status: 'Pending' } },
 *   { waveId: 2, name: 'Wave 2', projectId: 2, waveStatus: { status: 'In progress' } },
 *   { waveId: 3, name: 'Wave 3', projectId: 3, waveStatus: { status: 'Finished' } }
 * ];
 * const currentWaveId = 1;
 * const onClickModuleSelection = (country, waveId) => {
 *   console.log(`Module selection clicked for wave ${waveId} in ${country}`);
 * };
 * const onClickEditWave = (country, waveId) => {
 *   console.log(`Edit wave clicked for wave ${waveId} in ${country}`);
 * };
 * const onClickModulePreview = (country, waveId) => {
 *   console.log(`Module preview clicked for wave ${waveId} in ${country}`);
 * };
 * const editWavesPrivilege = true;
 * const statusToStatusType = (status) => {
 *   const statusTypes = {
 *     'Pending': 'pendingStatus',
 *     'In progress': 'inProgressStatus',
 *     'Finished': 'finishedStatus'
 *   };
 *   return statusTypes[status] || 'defaultStatus';
 * };
 *
 * return (
 *   <AccordionDisplay
 *     filteredProjectList={filteredProjectList}
 *     country={country}
 *     items={items}
 *     currentWaveId={currentWaveId}
 *     onClickModuleSelection={onClickModuleSelection}
 *     onClickEditWave={onClickEditWave}
 *     editWavesPrivilege={editWavesPrivilege}
 *     statusToStatusType={statusToStatusType}
 *     onClickModulePreview={onClickModulePreview}
 *   />
 * );
 */
const AccordionDisplay = ({
  filteredProjectList,
  country,
  items,
  currentWaveId,
  onClickModuleSelection,
  onClickEditWave,
  editWavesPrivilege,
  statusToStatusType,
  onClickModulePreview,
}) => {
  /**
   * @function
   * @memberof AccordionDisplay
   * @param {String} id The id of the wave being viewed.
   * @description Handles click on the 'Module Selection' button.
   */
  const handleModuleSelectionClick = (id) => {
    onClickModuleSelection(country, id);
  };
  /**
   * @function
   * @memberof AccordionDisplay
   * @param {String} id - The id of the wave being viewed.
   * @description Handles click on the 'Preview' button.
   */
  const handleModulePreviewClick = (id) => {
    onClickModulePreview(country, id);
  };

  /**
   * @function
   * @memberof AccordionDisplay
   * @param {String} id - The id of the wave being viewed.
   * @description Handles click on the 'Edit Wave' button.
   */
  const handleEditWave = (id) => {
    onClickEditWave(country, id);
  };

  return (
    <div className={styles.container}>
      {items
        .filter((item) => filteredProjectList.includes(item.projectId))
        .map((item) => {
          return (
            <AccordionDisplayItem
              key={item.waveId}
              {...item}
              currentWaveId={currentWaveId}
              onClickModuleSelection={handleModuleSelectionClick}
              onClickEditWave={handleEditWave}
              statusToStatusType={statusToStatusType}
              editWavesPrivilege={editWavesPrivilege}
              onClickModulePreview={handleModulePreviewClick}
            />
          );
        })}
    </div>
  );
};

AccordionDisplay.propTypes = {
  filteredProjectList: PropTypes.arrayOf(PropTypes.number).isRequired,
  country: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      waveId: PropTypes.number,
      name: PropTypes.string,
      projectId: PropTypes.number,
      waveStatus: PropTypes.shape({
        status: PropTypes.string,
      }),
    })
  ).isRequired,
  currentWaveId: PropTypes.number.isRequired,
  onClickModuleSelection: PropTypes.func.isRequired,
  onClickEditWave: PropTypes.func.isRequired,
  editWavesPrivilege: PropTypes.bool.isRequired,
  statusToStatusType: PropTypes.func.isRequired,
  onClickModulePreview: PropTypes.func.isRequired,
};

AccordionDisplay.defaultProps = {
  filteredProjectList: [],
  country: 'defaultCountry',
  items: [
    {
      waveId: -1,
      name: 'Default Name',
      projectId: 0,
      waveStatus: {
        status: 'Default Status',
      },
    },
  ],
  currentWaveId: 0,
  onClickModuleSelection: () => {},
  onClickEditWave: () => {},
  editWavesPrivilege: false,
  statusToStatusType: (status) => `Status-${status}`,
  onClickModulePreview: () => {},
};

export default AccordionDisplay;
