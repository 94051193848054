import React from 'react';

// imports for syncfusion rich text editor
import './RichTextEditor.css';
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';

/**
 * @component RichTextEditor
 * @param {String} label - The label to display above the rich text editor.
 * @param {String} name - The name attribute for the rich text editor.
 * @param {String} value - The initial value for the rich text editor.
 * @param {String} id - The unique identifier for the rich text editor.
 * @param {Function} handleChange - The callback function to execute when the content of the rich text editor changes.
 *
 * @description Renders a rich text editor component for inputting and editing text with various formatting options.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleEditorChange = (id, content, name) => {
 *   console.log(`RichTextEditor ${id} with name ${name} changed content:`, content);
 * };
 *
 * return (
 *   <RichTextEditor
 *     label="Description"
 *     name="description"
 *     value="<p>Initial content</p>"
 *     id="richtexteditor-1"
 *     handleChange={handleEditorChange}
 *   />
 * );
 */
const RichTextEditor = ({ label, name, value, id, handleChange }) => {
  return (
    <>
      <label htmlFor={name}>{label}</label>
      <RichTextEditorComponent
        height={250}
        htmlAttributes={{ name: { name } }}
        value={value}
        change={(e) => handleChange(id, e.value, name)}
        id={name}
        data-testid={name}
      >
        <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
      </RichTextEditorComponent>
    </>
  );
};

export default RichTextEditor;
