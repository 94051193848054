import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatusGrid.module.css';
import moment from 'moment';

/**
 * @component StatusGridItem
 * @param {Object} process - The process object with a 'name' property representing the process name.
 * @param {'Pending' | 'In progress' | 'Finished'} status - The status of the process.
 * @param {'default' | 'success' | 'progress' | 'error'} statusType
 * @param {string} startDate - The start date of the process.
 * @param {string} endDate - The end date of the process.
 * @param {string} projectType - The type of the project associated with the status items.
 *
 * @description Renders a grid item displaying process information within the StatusGrid component.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const process = { name: 'Process 1' };
 * const status = 'In progress';
 * const statusType = 'progress';
 * const startDate = '2024-01-01';
 * const endDate = '2024-01-31';
 * const projectType = 'Sample Project';
 *
 * return (
 *   <StatusGridItem
 *     process={process}
 *     status={status}
 *     statusType={statusType}
 *     startDate={startDate}
 *     endDate={endDate}
 *     projectType={projectType}
 *   />
 * );
 */
const StatusGridItem = ({
  process,
  status,
  statusType,
  startDate,
  endDate,
  projectType,
}) => {
  const formattedStartDate = moment(startDate).utc().format('DD/MM/YYYY');
  const formattedEndDate = moment(endDate).utc().format('DD/MM/YYYY');

  if (
    projectType === 'Incidence tracker' &&
    process.name === 'Module selection'
  )
    return;
  return (
    <>
      <div className={`${styles.cell} ${styles.content}`}>{process.name}</div>
      <div className={`${styles.cell} ${styles.content} ${styles[statusType]}`}>
        {status}
      </div>
      <div className={`${styles.cell} ${styles.content}`}>
        {formattedStartDate}
      </div>
      <div className={`${styles.cell} ${styles.content}`}>
        {formattedEndDate}
      </div>
    </>
  );
};

StatusGridItem.propTypes = {
  process: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  statusType: PropTypes.oneOf(['default', 'success', 'progress', 'error']),
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

StatusGridItem.defaultProps = {
  process: { name: 'default' },
  status: 'default',
  statusType: 'default',
  startDate: 'default',
  endDate: 'default',
};

export default StatusGridItem;
