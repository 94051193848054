import * as actionTypes from '../actions/actionTypes';

const initialState = {
  notifications: {},
  isLoading: false,
  error: null,
};

const notificationStart = (state) => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

const notificationFail = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

// get notifications
const getNotificationsSuccess = (state, action) => {
  return {
    ...state,
    notifications: action.notifications,
    isLoading: false,
    error: null,
  };
};

// send notification
const sendNotificationSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
    error: null,
  };
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS_START:
      return notificationStart(state);
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return getNotificationsSuccess(state, action);
    case actionTypes.GET_NOTIFICATIONS_FAIL:
      return notificationFail(state, action);
    case actionTypes.SEND_NOTIFICATION_START:
      return notificationStart(state);
    case actionTypes.SEND_NOTIFICATION_SUCCESS:
      return sendNotificationSuccess(state);
    case actionTypes.SEND_NOTIFICATION_FAIL:
      return notificationFail(state, action);
    default:
      return state;
  }
};

export default notificationReducer;
