import React, { forwardRef, useEffect, useState } from 'react';
import styles from './ProcessComponent.module.css';
import DatePicker from '../datePicker/DatePicker';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../buttons/Button';

/**
 * @component ProcessComponent
 * @param {String} type - The type of the process.
 * @param {String} startDate - The start date of the process.
 * @param {String} endDate - The end date of the process.
 * @param {String} status - The status of the process.
 * @param {Function} onChange - Function to handle changes in the process.
 * @param {Boolean} isNewWave - Indicates whether the process is part of a new wave.
 * @param {Function} onDelete - Function to handle process deletion.
 * @param {Function} onRestore - Function to handle process restoration.
 * @param {Boolean} isValidFail - Indicates whether the process is valid or not.
 *
 * @description A component that displays information and controls for a wave process.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleProcessChange = (type, field, value) => {
 *   console.log(`Process ${type} changed ${field} to ${value}`);
 * };
 *
 * const handleProcessDelete = (type) => {
 *   console.log(`Process ${type} deleted`);
 * };
 *
 * const handleProcessRestore = (type) => {
 *   console.log(`Process ${type} restored`);
 * };
 *
 * return (
 *   <ProcessComponent
 *     type="Fieldwork"
 *     startDate="2024-01-01"
 *     endDate="2024-01-31"
 *     status="In progress"
 *     onChange={handleProcessChange}
 *     isNewWave={false}
 *     onDelete={handleProcessDelete}
 *     onRestore={handleProcessRestore}
 *     isValidFail={false}
 *   />
 * );
 */
const ProcessComponent = forwardRef(
  (
    {
      type,
      startDate,
      endDate,
      status,
      onChange,
      isNewWave,
      onDelete,
      onRestore,
      isValidFail
    },
    ref,
  ) => {
    const [procStatus, setProcStatus] = useState(
      status ? status : 'placeholder'
    );
    const [sDate, setSDate] = useState(startDate ? startDate : '');
    const [eDate, setEDate] = useState(endDate ? endDate : '');
    const [isClosed, setIsClosed] = useState(isNewWave ? false : true);
    const [validFail, setValidFail] = useState(false);

    /**
@method useEffect - 1st instance  
     * @memberof ProcessComponent
     *
     * @description When the value of the argument 'isValidFail' changes, the 'validFail' state variable is updated to the value of 'isValidFail'.
     */
    useEffect(() => {
      setValidFail(isValidFail);
    }, [isValidFail]);

    /**
@method useEffect - 2nd instance  
     * @memberof ProcessComponent
     *
     * @description When the process status, start date and end date are modified, and provided that they are all filled in, the value of the 'isClosed' state variable is set to 'false'.
     */
    useEffect(() => {
      if (startDate && endDate && status && isClosed) {
        setIsClosed(false);
        }
    }, [status, startDate, endDate]);

    /**
@method useEffect - 3rd instance  
     * @memberof ProcessComponent
     *
     * @description When the value of the argument 'status' changes, the 'procStatus' state variable is updated to the value of 'status'.
     */
    useEffect(() => {
      setProcStatus(status);
    }, [status]);

    /**
@method useEffect - 4th instance  
     * @memberof ProcessComponent
     *
     * @description When the value of the argument 'startDate' changes, the 'sDate' state variable is updated to the value of 'startDate'.
     */
    useEffect(() => {
      setSDate(startDate);
    }, [startDate]);

    /**
@method useEffect - 5th instance  
     * @memberof ProcessComponent
     *
     * @description When the value of the argument 'endDate' changes, the 'eDate' state variable is updated to the value of 'endDate'.
     */
    useEffect(() => {
      setEDate(endDate);
    }, [endDate]);

    /**
     * @function
     * @memberof ProcessComponent
     * @param {Event} event
     *
     * @description when a process' status changes the 'procStatus' state variable is set to the value of the event target, and the 'onChange' function, which is passed to the component as an argument, is called with the process type, 'status' and the process status as arguments' to update the process status.
     */
    const handleStatusChange = (event) => {
      const val = event.target.value;
      setProcStatus(val);
      onChange(type, 'status', val);
    };

    /**
     * @function getDate
     * @param {Date} date - The date to be processed.
     * @param {String} id - The identifier associated with the date.
     *
     * @description A function that processes a date, sets its time to the end of the day (EOD), and converts it to an ISO string before calling the `onChange` function with the processed date. This function is used for handling changes in start and end dates of a process.
     */
    const getDate = (date, id) => {
      date.setHours(18); //setting time to EOD, so different timezones should be unaffected of the server converting the date
      const isoDate = date.toISOString();
      onChange(type, id, isoDate);
    };

    /**
     * @function
     * @memberof ProcessComponent
     *
     * @description When a process is deleted and this function is called it sets 'isClosed' to 'true' for the process, 'validFail' to 'false', and calls the 'onDelete' function that is passed as an argument to the component with the process type as argument.
     */
    const handleProcessDelete = () => {
      setIsClosed(true);
      onDelete(type);
      setValidFail(false);
    };

    /**
     * @function
     * @memberof ProcessComponent
     *
     * @description When a process is restored and this function is called it sets 'isClosed' to 'false' for the process, 'validFail' to the value of the 'isValidFail' argument, and calls the 'onRestore' function that is passed as an argument to the component with the process type as argument.
     */
    const handleProcessRestore = () => {
      setIsClosed(false);
      onRestore(type);
      setValidFail(isValidFail);
    };

    return (
      <div
        className={`${styles.Wrapper} ${isClosed ? styles.NoProcess : ''} ${
          validFail ? styles.Error : ''
        }`}
        ref={ref}
      >
        {isClosed ? (
          <Button
            type={'selectionMini'}
            testId={`restore-button-${type}`}
            handleOnClick={() => handleProcessRestore()}
          >
            Restore
          </Button>
        ) : (
          <Button type={'close'} handleOnClick={() => handleProcessDelete()} />
        )}
        <div className={`${styles.Type} ${isClosed && styles.DisabledProcess}`}>
          {type} {isClosed ? ' - Removed' : ''}
        </div>
            {!isClosed ? (
                <div className={styles.SelectionMenu} data-testId={`div-${type}`}>
            <div className={styles.InputItem}>
                        <label>Start date</label>
                        <DatePicker id={'startDate'} getDate={getDate} date={sDate} />
            </div>
            <div className={styles.InputItem}>
              <label>End date</label>
              <DatePicker id={'endDate'} getDate={getDate} date={eDate} />
            </div>
            <div className={styles.InputItem}>
              <Dropdown
                id={'dd1'}
                label={'Status'}
                value={procStatus}
                placeholder={' '}
                options={[
                  { label: 'Pending', value: 'Pending' },
                  { label: 'In progress', value: 'In progress' },
                  { label: 'Finished', value: 'Finished' },
                ]}
                onChange={handleStatusChange}
              />                       
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);

ProcessComponent.propTypes = {
  type: PropTypes.oneOf([
    'Module selection',
    'Scripting',
    'Fieldwork',
    'Data processing',
    'Reporting',
  ]).isRequired,
};

export default ProcessComponent;
