import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './QuestionTypeSelection.module.css';

/**
 * @component QuestionTypeSelection

 * @param {Function} getClicked - A function to be called when a question type button is clicked.
 * @param {Number} tabIndex - The tabindex for the question type buttons.
 * @param {Array} questionTypes - An array of question types to be rendered as buttons.
 * @param {String} activeBtnId - The ID of the currently active question type button.
 * 
 * @description A component that renders a list of question types as buttons and allows the user to select a question type by clicking or using keyboard navigation.
 * @returns {JSX.Element}
 * 
* @example
 * const questionTypes = [
 *   { id: '1', name: 'Multiple Choice' },
 *   { id: '2', name: 'Single Choice' },
 *   { id: '3', name: 'Open-Ended' }
 * ];
 * 
 * const handleQuestionTypeClick = (id) => {
 *   console.log(`Question type clicked with id: ${id}`);
 * };
 * 
 * return (
 *   <QuestionTypeSelection
 *     getClicked={handleQuestionTypeClick}
 *     tabIndex={0}
 *     questionTypes={questionTypes}
 *     activeBtnId="1"
 *   />
 * );
 */
const QuestionTypeSelection = ({
  getClicked,
  tabIndex,
  questionTypes,
  activeBtnId,
}) => {
  const [activeBtn, setActiveBtn] = useState(activeBtnId ? activeBtnId : '1');

  /**
   * @function
   * @memberof QuestionTypeSelection
   * @param {String} id Question id.
   *
   * @description The function updates the 'activeButton' state variable with the id of the question, and calls the 'getClicked' function with the question's id as its argument.
   *
   * @returns {String} id
   */
  const clickHandler = (id) => {
    setActiveBtn(id);
    getClicked(id);
    return id;
  };

  return (
    <div data-testid="id-questionTypeSelection">
      {questionTypes.map((obj, index) => (
        <button
          className={`${classes.button} ${classes.borderMiddle} ${
            index === 0 && classes.borderLeft
          } ${index === questionTypes.length - 1 && classes.borderRight} ${
            activeBtn === obj.id && classes.activeBtn
          }`}
          key={obj.id}
          data-testid={obj.id}
          onClick={() => clickHandler(obj.id)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              getClicked(obj.id);
            }
          }}
          tabIndex={tabIndex}
        >
          {obj.name}
        </button>
      ))}
    </div>
  );
};

QuestionTypeSelection.propTypes = {
  getClicked: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  questionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeBtnId: PropTypes.string,
};

QuestionTypeSelection.defaultProps = {
  getClicked: () => {},
  tabIndex: 0,
  activeBtnId: '1',
};

export default QuestionTypeSelection;
