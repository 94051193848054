import React from 'react';
import ModalContent from '../ModalContent/ModalContent';
import { useSelector } from 'react-redux';

const RequestModuleSelectionUnlock = ({ id, handleConfirm, handleClose }) => {
  const messages = useSelector((state) => state.message.messages);
  
  return (<ModalContent
    id={id}
    title={messages?.requestModuleSelectionUnlock?.title}
    content={messages?.requestModuleSelectionUnlock?.text}
    confirmBtnTitle={'Request selection unlock'}
    cancelBtnTitle={'Cancel'}
    handleConfirm={handleConfirm}
    handleCancel={handleClose}
   />);
};

export default RequestModuleSelectionUnlock;
