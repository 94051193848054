import * as React from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import './DatePicker.css';

/**
 * @component DatePicker
 * @param {Function} getDate - The callback function to execute when a date is selected.
 * @param {String} id - The unique identifier for the DatePicker component.
 * @param {String} date - The initial date value for the DatePicker.
 *
 * @description Renders a date picker component using Syncfusion's DatePickerComponent.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleDateChange = (selectedDate, id) => {
 *   console.log(`Date selected for ${id}: ${selectedDate}`);
 * };
 *
 * return (
 *   <DatePicker
 *     getDate={handleDateChange}
 *     id="datepicker-1"
 *     date="2024-06-14"
 *   />
 * );
 */
function DatePicker({ getDate, id, date }) {
  const currentVal = date ? date : '';

  const handleChange = (event) => {
    const date = event.target.value;
    getDate(date, id);
  };
  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="datepicker-control-section">
          <DatePickerComponent
            value={currentVal}
            format="dd/MM/yyyy"
            placeholder="Day / Month / Year"
            onChange={handleChange}
          ></DatePickerComponent>
        </div>
      </div>
    </div>
  );
}
export default DatePicker;
