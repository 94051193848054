import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Tabs.module.css';

/**
 * @component Tabs
 * @param {Array} labelsTabs - An array of tab labels.
 * @param {String} idCurrent - The ID of the currently active tab.
 * @param {Function} handleOnClick - Function to handle click on a tab.
 *
 * @description A component that renders a tabbed interface with clickable tabs.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const labelsTabs = [
 *   { id: 0, name: 'Tab 1' },
 *   { id: 1, name: 'Tab 2' },
 *   { id: 2, name: 'Tab 3' }
 * ];
 *
 * const handleTabClick = (id) => {
 *   console.log(`Tab clicked with id: ${id}`);
 * };
 *
 * return (
 *   <Tabs
 *     labelsTabs={labelsTabs}
 *     idCurrent={0}
 *     handleOnClick={handleTabClick}
 *   />
 * );
 */
const Tabs = ({ labelsTabs, idCurrent, handleOnClick }) => {
  const [current, setCurrent] = useState(idCurrent);

  /**
   * @method useEffect
   * @memberof Tabs
   *
   * @description Update the state variable holding the current id.
   */
  useEffect(() => {
    if (current !== idCurrent) {
      setCurrent(idCurrent);
      }
  }, [idCurrent, current]);

  /**
   * @function
   * @memberof Tabs
   * @param {String} id Id of the clicked tab.
   *
   * @description When a tab is clicked the value of the 'current' state variable is set to the value of the id of the clicked tab, and the 'handleOnClick' function is called with the id of the clicked tab as an argument.
   */
  const clickHandler = (id) => {
    setCurrent(id);
    handleOnClick(id);
    return id;
  };

  return (
    <div>
      {labelsTabs.map((label, index) => {
        return (
          <button
            aria-current={label.id === current}
            key={label.id}
            className={`${styles.btn} 
            ${label.id === current && styles.activeBtn} 
            ${index === 0 && styles.btnFirst} 
            ${index === labelsTabs.length - 1 && styles.btnLast}`}
            onClick={(e) => {
              e.preventDefault();
              clickHandler(label.id);
            }}
          >
            {label.name}
          </button>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  labelsTabs: PropTypes.array.isRequired,
  idCurrent: PropTypes.number.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  labelsTabs: [{ id: 0, name: 'default' }],
  idCurrent: 0,
  handleOnClick: (id) => {
    return id;
  },
};

export default Tabs;
