import React, { useEffect, useContext, createContext, useMemo } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from "../store/actions";
export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [{isAuthenticated, user, msalUser, userName}, isLoading] = useSelector((state) => [state.app.user, state.app.isLoading]); 
    
    useEffect(() => {
        dispatch(getUserData());
    }, []);

  const login = () => {
    window.location.href = "/api/auth/login";
  };

  const logout = () => {
    window.location.href = "/api/auth/logout";
  };

    const authValue = useMemo(() => {
        return {
            isAuthenticated: isAuthenticated && user,
            user: user ?? null,
            msalUser,
            userName,
            isLoading,
            login,
            logout
        };
    }, [isAuthenticated, user, msalUser, userName, isLoading]); 
  
  return (
    <AuthContext.Provider
          value={authValue}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
