import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './WaveSelection.module.css';
import CountryMenu from '../../containers/countryMenu/CountryMenu';
import WaveStatus from '../../containers/waveStatus/WaveStatus';

import { useParams } from 'react-router-dom';
import {
  updateTitle,
  checkUXPrivileges,
  statusToStatusType,
} from '../../../utility/utility';
import Logo from '../../ui/logo/Logo';

import { ClearWave, updateCrumbs, ClearModules } from '../../../store/actions';

/**
 * @component WaveSelection
 * @description This component is the root route of the application, and serves as a main hub that allows us to see, create and edit other waves.
 * More information pertaining to each child compoent of WaveSelection can be found in the components' documentation pages.
 * 
 * This component is a child of the App component. 

 * @returns {JSX} An element containing a sidebar, CountryMenu, that allows the user to scroll to a specific country ( if the user has access to more than one country ). The main content of WaveSelection is the WaveStatus component, that allows us to perform certain actions ( such as creatin a new wave, or editing an already existing one, depending on the role of the user accessing the application) to a country's waves.
 */
const WaveSelection = () => {
  const [currentWaveId, setCurrentWaveId] = useState(-1);
  const [currentCountryId, setCurrentCountryId] = useState(-1);
  const [scrollCountryId, setScrollCountryId] = useState(-1);
  const { countries, isLoading, error } = useSelector((state) => state.wave);

  /**
   * @constant
   * @type {Array}
   * @description This variable selects the uxPrivileges array from state and allows us to check whether a user can perform certain actions (such as create and edit waves, add custom questions, change wave statuses, etc).
   * The check is performed using the 'checkUXPrivileges' function, that receives ths uxPrivileges array and a string of the name of the privilege we are checking for as parameters.
   */
  const { uxPrivileges } = useSelector((state) => state.app.user.user.role);
  const { dataPrivileges } = useSelector((state) => state.app.user.user.role);
  const assignedCountries = useSelector(
    (state) => state.app.user.user.userCountrys
  );
  const dispatch = useDispatch();
  let { country } = useParams();
  const showCountryMenu = useRef(false);

  /**
   * @method useEffect
   * @memberof WaveSelection
   * @description When the component initially mounts, it dispatches ClearWave and ClearModules. This is required to ensure in the case where we accessed a wave before, and its wave and modules were stored in state, we remove them to ensure we do not retain any information of a previous wave when we access a new one, as this can cause unexpected behaviors and incorrect content being displayed.
   * @param {Array} emptyDependencyArray Only executes the effect when the component first mounts.
   */
  useEffect(() => {
    dispatch(ClearWave());
    dispatch(ClearModules());

    updateTitle('');
  },[dispatch]);

  /**
   * @method useEffect
   * @memberof WaveSelection
   * @description When the 'assignedCountries' variable updates, it checks if the user has access to more than one country. Depending on the result it will do one of two things:
   * - Render the CountryMenu componment if the user has access to more than one country. Clicking on any country in the component will scroll the page down to that specific country.
   * - Not render the CountryMenu component if the user has access to only one country.  This is because clicking on a country in the CountryMenu component scrolls the page to the clicked country, but this is not needed if the user only has access to a single country.
   * @param {Array} assignedCountries Array containing all countries the user has access to.
   */
  useEffect(() => {
    if (
      assignedCountries.length > 1 ||
      dataPrivileges.filter(
        (p) => p.name.toLowerCase() === 'all waves in all countries'
      ).length > 0
    ) {
      showCountryMenu.current = true;
    } else {
      showCountryMenu.current = false;
    }
  }, [assignedCountries, dataPrivileges]);

  /**
   * @method useEffect
   * @memberof WaveSelection
   * @description  Updates the breadcrumbs when the 'country' changes in the URL params.
   *
   * A country will be present in the URL params only if the user clicks on the country's name in the Breadcrumbs component. Doing so will redirect the user to '/waves/country'.
   * @param {String} country String variable containing the name of the country in the URL params.
   */
  useEffect(() => {
    dispatch(
      updateCrumbs([
        { text: 'Waves', link: '/' },
        {
          text: country,
          link: `waves/${country}`,
        },
      ])
    );
  }, [country,dispatch]);

  /**
   * @method useEffect
   * @memberof WaveSelection
   * @description  Once the component is mounted and when 'countries' updates the effect scrolls the page down to the appropriate country (taken from the URL params). This effect is executed only if there is a country in the URL params, and the user has access to more than one country.
   * @param {Array} countries Array of all 'countries' the user has access to based on their role.
  
   */
  useEffect(() => {
    if (country !== undefined && countries && countries.length > 0) {
      let tempCntry = countries.filter(
        (cntry) => cntry.name.toLowerCase() === country.toLowerCase()
      )[0]?.countryId;
      setScrollCountryId(tempCntry);
      setCurrentCountryId(tempCntry);
    }
  }, [countries,country]);

  /**
   * @function
   * @memberof WaveSelection
   * @param {String} status String containing the status for the process.
   * @description Returns a string that is used for setting the appropriate style for each status in the StatusGridItem component based on the status of the process.
   * @returns {'success' | 'progress' | 'error' | 'default'} String containing the status.
   */
  const statusToStatusType = (status) => {
    switch (status) {
      case 'Finished':
        return 'success';
      case 'In progress':
        return 'progress';
      case 'Failed':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <div
      className={`${styles.Page} ${
        showCountryMenu.current ? '' : styles.Fullscreen
      }`}
    >
      <Logo />
      {isLoading && <div className={styles.loading}>Loading...</div>}
      {error && <div className={styles.error}>{error}</div>}
      {showCountryMenu.current ? (
        <CountryMenu
          countries={countries}
          onClickWave={setCurrentWaveId}
          onClickCountry={setCurrentCountryId}
          currentCountryId={currentCountryId}
          scrollTo={scrollCountryId}
        />
      ) : null}
      <WaveStatus
        countries={countries}
        setCurrentWaveId={setCurrentWaveId}
        currentWaveId={currentWaveId}
        statusToStatusType={statusToStatusType}
        scrollTo={currentCountryId}
        editWavesPrivilege={checkUXPrivileges(uxPrivileges, 'editWave')}
      />
    </div>
  );
};

export default WaveSelection;
