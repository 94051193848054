import { useState, useEffect, useRef } from 'react';

/**
 * @function useComponentVisible
 * @param {Boolean} initialIsVisible - The initial visibility state of the component.
 *
 * @description Custom React hook that handles the visibility of a component based on clicks outside of it.
 *
 * @returns {Object} An object containing the ref for the component, the current visibility state, and a function to set the visibility state.
 */
export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);
  /**
   *
   * @param {*} event
   */

  /**
   * @function
   * @memberof useComponentVisible
   * @param {Event} event
   *
   * @description When the user clicks outside of the component the function sets the value of the 'isComponentVisible' state variable to 'false'.
   */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      console.log('click');
      setIsComponentVisible(false);
    }
  };

  /**
   * @method useEffect
   * @memberof useComponentVisible
   * @description When the component mounts a click event listener is added to the document, assigning the function 'handleClickOutside' to the click event' The function is removed from the document when the component unmounts.
   */
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
