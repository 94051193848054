import React from 'react';
import styles from './Modal.module.css';

/**
 * @component ModalDialog
 * @param {Boolean} show - Flag to determine whether the modal is shown or hidden.
 * @param {JSX.Element} content - The content to be displayed inside the modal.
 *
 * @description Renders a modal dialog component that can display content when shown.
 *
 * @returns {JSX.Element|null}
 *
 * @example
 * const [showModal, setShowModal] = React.useState(false);
 *
 * const toggleModal = () => {
 *   setShowModal(!showModal);
 * };
 *
 * return (
 *   <>
 *     <button onClick={toggleModal}>Toggle Modal</button>
 *     <ModalDialog
 *       show={showModal}
 *       content={<div>Here is the modal content</div>}
 *     />
 *   </>
 * );
 */
function ModalDialog({ show, content }) {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.Modal}>
      <div className={styles.ModalContent}>{content}</div>
    </div>
  );
}

export default ModalDialog;
