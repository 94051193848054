import * as actionTypes from './actionTypes';
import axios from '../../axiosInstance';
import {
  GetMessagesObject
} from '../../utility/utility';

export const fetchMessageListStart = () => {
  return {
    type: actionTypes.FETCH_MESSAGE_LIST_START,
  };
};

export const fetchMessageListSuccess = (result) => {
  return {
    type: actionTypes.FETCH_MESSAGE_LIST_SUCCESS,
    messages: GetMessagesObject(result)
  };
};

export const fetchMessageListFail = (error) => {
  return {
    type: actionTypes.FETCH_MESSAGE_LIST_FAIL,
    error: error,
  };
};

export const fetchMessageList = () => {
  return (dispatch) => {
    dispatch(fetchMessageListStart());
     axios
       .get('Messages')
       .then((res) => {
         if (res.status !== 200) {
           throw new Error(res.statusText);
         }
         const messages = res.data;
         dispatch(fetchMessageListSuccess(messages));
       })
       .catch((error) => {
         dispatch(fetchMessageListFail(error));
       });
  };
};
