import * as appMessages from '../../../utility/messages';

/**
 * @function
 * @memberof CreateEditWave
 * @param {Array} arr Array of objects containing each process for this wave and each process contains key value pairs corresponding to their 'endDate', 'startDate', 'processId', 'waveId' and 'status'.
 * @param {Array} statList Array of objects where each object contains a wave's 'waveStatusId' and 'status'.
 * @param {Array} waveProcessList Array of objects where each object corresponds to a process, and contains key value pairs corresponding to a process' 'processId', 'name', and 'sequence'.
 *
 * @description The function accepts all processes corresponding to the wave, each wave's statusId and status, as well as the processId, and name of all available processes. Then, based on this the processes are sorted based on their status, and the processId of the first process whose status is 'In progress' is returned.
 *
 * @returns {Number} Id of the first wave process ( based on their 'sequence' value ) whose status is 'In progress'.
 */
export const setWaveStatus = (arr, statList, waveProcessList) => {
  let waveStatus = null;
  let firstInprogress,
    firstPending,
    firstFinished = null;

  function getProcIdToStatId(id) {
    let processName = waveProcessList.find(
      (proc) => proc.processId === id
    ).name;
    let statusId = statList.find(
      (stat) => stat.status === processName
    ).waveStatusId;
    return statusId;
  }
  arr.sort((a, b) => a.processId - b.processId);
  arr.forEach((el) => {
    if (el.status === 'In progress' && !firstInprogress) {
      firstInprogress = getProcIdToStatId(el.processId);
    } else if (el.status === 'Pending' && !firstPending) {
      firstPending = getProcIdToStatId(el.processId);
    } else if (el.status === 'Finished' && !firstFinished) {
      firstFinished = getProcIdToStatId(el.processId);
    }
  });

  if (firstInprogress) {
    waveStatus = firstInprogress;
  } else if (firstPending && firstFinished) {
    waveStatus = firstPending;
  } else if (firstPending) {
    waveStatus = statList.find(
      (stat) => stat.status === 'Not started'
    ).waveStatusId;
  } else if (firstFinished) {
    waveStatus = statList.find(
      (stat) => stat.status === 'Finished'
    ).waveStatusId;
  }
  return waveStatus;
};

/**
 * @function
 * @memberof CreateEditWave
 * @param {String} processName 'name' of the process on which this function is called.
 * @param {Array} processList Array of objects. Each object contains a process' 'processId', 'name' and 'sequence'.
 *
 * @description The function accepts a process' name, and an array of processes. It checks the 'processList' argument for the process whose name equals 'processName', and returns the process' id.
 *
 * @returns {Number} 'processId' parameter value of the process whose name equals 'processName' in the processList array.
 */
export const getProcessId = (processName, processList) => {
  let process = processList.find((process) => process.name === processName);
  let processId = process.processId;
  return processId;
};

/**
 * @function
 * @memberof CreateEditWave
 * @param {Array} processes Array of objects where each object contains all information pertaining to a single process.
 * @param {Number} procId Id of the process.
 * @param {Number} waveId Id of the wave being edited.
 *
 * @description The function takes a process id, a list of processes, and a wave id. Then it finds the corresponding process in the 'processes' array ( based on the 'processId' property each process contains), and makes sure that the process being checked is part of the same wave that this function is taking effect on ( based on the 'waveId' argument and the corresponding property in the process object). Once the correct process is found and the function confirmed that it is part of the correct wave, the process is returned.
 *
 * @returns {Object} The function returns the correct object from the 'processes' array based on the 'procId' argument passed to it.
 */
export const setProcessInState = (processes, procId, waveId) => {    
  let process = processes.find(
    (proc) => proc.processId === procId && proc.waveId === parseInt(waveId)
    ); //waveId coming in as a string ?!?!
        
  return process;
};

/**
 * @function
 * @memberof CreateEditWave
 * @param {Object} process Object containing all data pertaining to this process
 * @param {Object} processObj Object containing key - value pairs where the key represents a process' 'processId' value, and the value the object holds is another object containing the following keys: "startDate", "endDate", "processId", and "status".
 *
 * @description When creating a new wave, setting the start date, end date, and status for any process will cause all other processes to automatically have their start date, end date, and status set. The status will be set depending on the status of the process that was first filled in, and which process was filled in. For the first process, subsequent processes are set to start a week apart, each beginning on the end date of the previous process. For processes that are not first, each preceding process will have its end date adjusted to the start date of the next process, and its start date set to 7 days before its own end date.
 *
 * **NOTE**: This function is only executed when creating a wave, after the user has set a start date, end date and process status for the first process ( can be any of the available processes ).
 *
 * @returns {Object} The function returns an updated version of the 'processObj' object with each process object in it having its start date, end date and status set according to the description above.
 */
export const autofillProcessArr = (process, processObj) => {
  // let newProcessArr = [...processArr]
  function getDifference(a, b) {
    return a - b;
  }
  function getStartEndDate(startDate, endDate, weeks) {
    let newStartDate;
    let newEndDate;
    if (weeks < 0) {
      //Previous processes
      //date initialized as start / end date to preserve year setting.
      newStartDate = new Date(startDate);
      newEndDate = new Date(startDate);
      newEndDate.setDate(startDate.getDate() + 7 * (weeks + 1));
      newStartDate.setDate(startDate.getDate() + 7 * weeks);
    } else {
      //Next processes
      newStartDate = new Date(endDate);
      newEndDate = new Date(endDate);
      newStartDate.setDate(endDate.getDate() + 7 * (weeks - 1));
      newEndDate.setDate(endDate.getDate() + 7 * weeks);
    }
    return {
      procStartDate: newStartDate.toISOString(),
      procEndDate: newEndDate.toISOString(),
    };
  }

  function setStatus(procStat, weekDiff) {
    let newStatus = 'placeholder';
    if (procStat === 'Pending') {
      newStatus = 'Pending';
    } else if (procStat === 'In progress' || procStat === 'Finished') {
      if (weekDiff < 0) {
        newStatus = 'Finished';
      } else {
        newStatus = 'Pending';
      }
    }
    return newStatus;
  }

  let allProcesses = [
    { id: 1, name: 'Module selection' },
    { id: 2, name: 'Scripting' },
    { id: 3, name: 'Fieldwork' },
    { id: 4, name: 'Data processing' },
    { id: 5, name: 'Reporting' },
  ];
  let firstProcess = process.processId;
  let firstStart = new Date(process.startDate);
  let firstEnd = new Date(process.endDate);
  let firstStatus = process.status;
  console.log(process, firstStart, firstEnd);
  allProcesses.forEach((obj) => {
    if (obj.id !== firstProcess) {
      let weeksDiff = getDifference(obj.id, firstProcess);
      let { procStartDate, procEndDate } = getStartEndDate(
        firstStart,
        firstEnd,
        weeksDiff
      );
      let procStatus = setStatus(firstStatus, weeksDiff); //this will come from DB at some point
      let newObj = {
        processId: obj.id,
        processName: obj.name,
        startDate: procStartDate,
        endDate: procEndDate,
        status: procStatus,
      };
      processObj[obj.id] = newObj;
    }
  });

  console.log(processObj);

  return processObj;
};

/**
 * @function
 * @memberof CreateEditWave
 * @param {Array} processArr Array of processes existing for the current wave.
 * @param {Array} filteredArr Array of process objects sorted by their 'processId' key value - this array excludes 'Module selection' in 'Incidence tracker' projects.
 * @param {String} waveName Name of the wave being created.
 *
 * @description The function performs the following checks, and if any of them are not passed it updates the 'report' it generates to make it invalid. It also includes error messages and errors, as well the failed processes if any of them do not pass their corresponding checks.
 *  - No wave name: If the wave does not have a name the report will not be valid, as all waves must have a name.
 *  - Processes not filled in: The processes must contain a start date, end date and status, even if autofilled.
 *  - No processes added: If the user removed all processes from the wave the report will not be valid. The wave has to contain at least a process.
 *  - No processes are missing: The relevant processes for Incidence tracker are checked to make sure they are present  ( this does not include 'Module Selection' ).
 *
 * @returns {Object} Report object containing the properties below. If any of the checks above is failed the report will not be valid.
 *
 *  * **valid** - Boolean - true or false, depending on if the wave failed any of the checks above.
 *  * **errorMessages** - String - String containing all error messages corresponding to the checks that were failed.
 *  * **errors** - Array - Array of strings corresponding to each process that was failed.
 *  * **failedProcesses** - Object - Array of strings corresponding to each process that was failed. This is populated when there a process has data that was not filled in ( be it the start date, end date, or status) .
 */
export const validateWave = (processArr, filteredArr, waveName) => {
  const errorMessages = {};
  const report = {
    valid: true,
    errorMessages: '',
    errors: [],
    failedProcesses: {},
  };

  //1. check if there is at least one process added
  if (Object.keys(processArr).length === 0) {
    const messageObj = appMessages.setCreateWaveErrorMessage('missingProcess');
    errorMessages['missingProcess'] = messageObj.message;
    report.errors.push('missingProcess');
  }

  //2. check if there is a wave name
  if (!waveName) {
    const messageObj = appMessages.setCreateWaveErrorMessage('missingName');
    errorMessages['missingName'] = messageObj.message;
    report.errors.push('missingName');
  }

  // checking for date stuff


  //3. check if all process info is included &&...
  const tempArr = Object.values(processArr);
  tempArr.forEach((process) => {
    if (
      (!process.hasOwnProperty('startDate') ||
        !process.hasOwnProperty('endDate') ||
        !process.hasOwnProperty('status')) &&
      !(process.hasOwnProperty('deleted') && process.deleted === true)
    ) {
      const messageObj = appMessages.setCreateWaveErrorMessage('missingValue');
      errorMessages['missingValue'] = messageObj.message;
      report.errors.push('missingValue');
      report.failedProcesses[process.processId] = process.processName;
    }
    if (process.hasOwnProperty('startDate') && process.hasOwnProperty('endDate') && new Date(process.startDate).getTime() > new Date(process.endDate).getTime()){
      const messageObj = appMessages.setCreateWaveErrorMessage('incorrectDate');
      errorMessages['incorrectDate'] = messageObj.message;
      report.errors.push('incorrectDate');
      report.failedProcesses[process.processId] = process.processName;
    }
  });

  //...&& check process filter for Incidence tracker
  if (filteredArr.length === 0) {
    const messageObj = appMessages.setCreateWaveErrorMessage('missingProcess');
    errorMessages['missingProcess'] = messageObj.message;
    if (!report.errors.includes('missingProcess')) {
      report.errors.push('missingProcess');
    }
  }

  if (Object.values(errorMessages).length > 0) {
    report.valid = false;
    report.errorMessages = appMessages.stackMessages(errorMessages);
    console.log(report);
    return report;
  } else {
    console.log(report);
    return report;
  }
};

/**
 * @function
 * @memberof CreateEditWave
 * @param {Object} reportObj Object containing error report
 *
 * @description When this function is called (which happens because the report is not valid ) the report is evaluated to see where the error is coming from, be it the Name field for the wave, or one of the processes.
 * @returns The function returns either a number or a string:
 *
 *  - String: the function returns 'wnb' which shows that the wave does not have a name
 *  - Number: the function returns a number when one of the processes failed to validate in the report ( meaning that it either does not have a start date, end date, or status). The number corresponds to the sequence value of the failed process.
 */
export const getScrollElement = (reportObj) => {
  let scrollEl = '';
  let waveName = reportObj.errors.includes('missingName') || null;
  if (waveName) {
    scrollEl = 'wnb';
  } else if (Object.keys(reportObj.failedProcesses).length > 0) {
    let el = Math.min(...Object.keys(reportObj.failedProcesses));
    scrollEl = el;
  }
  return scrollEl;
};
