import { Document, Packer, Paragraph, HeadingLevel, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { GetFormattedLocalQuestionName, getStatus } from '../../../utility/utility';
import { cloneDeep as _cloneDeep } from 'lodash';
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} selectedModules Array containing all modules that have been selected for this wave.
 * @param {Array} customQuestions Array containing all local questions for this wave.
 * @param {Boolean} isCountrySpecificQuestionsDetected Boolean for if a wave contains country specific questions.
 * @description The function accepts all data required to generate the preview and populates the 'moduleDocxContent' by calling  buildModulesDocxContent method and localQuestoinDocxContent by calling buildCustomQuestionDocxContent method. Once the function finished its execution. This method returns all the data for the wave's module selection ( including if it has country specific data, the modules and their data, as well as the local questions).
 */
const buildDocxPageContents = (
  selectedModules,
  customQuestions,
  isCountrySpecificQuestionsDetected
) => {
 const moduleDocxContent = buildModulesDocxContent(_cloneDeep(selectedModules),isCountrySpecificQuestionsDetected);
 const localQuestoinDocxContent = buildCustomQuestionDocxContent(_cloneDeep(customQuestions));
 return [moduleDocxContent, localQuestoinDocxContent];
};

const emptyLine = new Paragraph({
  text: " ",
});
const qTextPara = new Paragraph({
  children: [
    new TextRun({
      text: "Question text:",
      bold: true,
    }),
  ],
});

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} selectedModules Array containing all modules that have been selected for this wave.
 * @param {Boolean} isCountrySpecificQuestionsDetected Boolean for if a wave contains country specific questions.
 * @description The function generates content related to module questions.
 */
const buildModulesDocxContent = (
  selectedModules,
  isCountrySpecificQuestionsDetected
) => {
  const moduleDocxContent = [];
  moduleDocxContent.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Modules",
              size: 64,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
        emptyLine,
        !isCountrySpecificQuestionsDetected &&
          new Paragraph({
            children: [
              new TextRun({
                text: "No country specific data detected for this country",
                color: "ff0000",
              }),
            ],
          }),
    );

  // Iterate each module to turn the contents of each module question into docx elements
  selectedModules.forEach((module) => {
    moduleDocxContent.push(
        emptyLine,
        new Paragraph({
          children: [
            new TextRun({
              text: module.name,
              size: 44,
            }),
          ],
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: module.description,
              bold: true,
            }),
          ],
        }),
      );

    // Adds Module Questions into the variable that will be used to render the document
    const questionsDocxContent = buildModuleQuestionsDocxContent(module?.questions);

    //Add each question content to its module content
    questionsDocxContent.forEach((questionContent) => {
      moduleDocxContent.push(emptyLine, 
        ...questionContent);
    });
  });

  const modulesDocx = { children: [...moduleDocxContent] };
  return modulesDocx;
};

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} questions Array containing all questions related to a module.
* @description The function generates content related to questions and returns as list in doc format.
 */
const buildModuleQuestionsDocxContent = (questions) => {
  const questionsContent = questions.map((moduleQuestion) => {
    const qnName = new Paragraph({
      children: [
        new TextRun({
          text: moduleQuestion.name,
          size: 28,
        }),
      ],
      heading: HeadingLevel.HEADING_4,
    });

    const qnBase = new Paragraph({
      text: `Base: ${
        moduleQuestion.base ? moduleQuestion.base : "All respondents"
      }`,
    });
    //if no scripting instruction what we should do
    const qnScripting = new Paragraph({
      text: `Scripting instructions: ${moduleQuestion.scriptingInstructions}`,
    });

    const qnAddRules = new Paragraph({
      text: `Additional rules: ${
        moduleQuestion.additionalRules
          ? moduleQuestion.additionalRules
          : "No additional rules"
      }`,
    });

    //Variable code as Question name
    const qnVariableCode = new Paragraph({
      children: [
        new TextRun({ text: "Question name: ", bold: true }),
        new TextRun({
          text: moduleQuestion.variableCode,
          bold: true,
        }),
      ],
    });

    const moduleQuestionContent = [
      emptyLine,
      qnName,
      qnBase,
      qnScripting,
      qnAddRules,
      emptyLine,
      qnVariableCode,
    ];
    //Get Questiontext and Answer content
    const qnQuestionsTexts = buildQuestionTextAndAnswerContent(
      moduleQuestion.questionTextFields
    );

    //Append Questiontext and Answer content after its Question details
    qnQuestionsTexts.forEach((questionTextFieldContent) => {
      moduleQuestionContent.push(...questionTextFieldContent);
    });

    return moduleQuestionContent;
  });

  return questionsContent;
};
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} questionTextFields Array containing all questions related to a module.
 * @description The function generates content related to question text answer options and returns as list in doc format.
 */
const buildQuestionTextAndAnswerContent = (questionTextFields) => {
  const questionTextsContent = questionTextFields.map((questionTextField) => {
    const questionTextAnswerOptionsContent = [
      emptyLine,
      qTextPara,
      new Paragraph({
        text: questionTextField.questionText,
      }),
    ];
    if (questionTextField.answerText) {
      questionTextAnswerOptionsContent.push(
        new Paragraph({
          children: [new TextRun({ text: "Answers: ", bold: true })],
        })
      );
      questionTextAnswerOptionsContent.push(
        new Paragraph({ text: questionTextField.answerText })
      );
    }
    const answerRowsCount = questionTextField?.answerRowList?.length ?? 0;
    const answerColsCount = questionTextField?.answerColList?.length ?? 0;
    if (answerRowsCount > 0) {
      if (answerColsCount > 0 || questionTextField?.answerText) {
        // if answer text is not null or the answers are structured in rows and columns adds the text "Answer Rows:" before them
        questionTextAnswerOptionsContent.push(
          new Paragraph({
            children: [new TextRun({ text: "Answer Rows:", bold: true })],
          })
        );
      } else {
        // if answer text value is null and the answers are structured only in rows adds the text "Answers:" before them
        questionTextAnswerOptionsContent.push(
          new Paragraph({
            children: [new TextRun({ text: "Answers:", bold: true })],
          })
        );
      }
      questionTextField.answerRowList
        .sort((a, b) => a.sequence - b.sequence)
        .forEach((answerRow) => {
          // adds answerRows content for each question
          questionTextAnswerOptionsContent.push(
            new Paragraph({
              text: `${answerRow.code}. ${answerRow.text}`,
            })
          );
        });
    }
    
    if (answerColsCount > 0) {
        // If there is options in cols, add "Answer Columns:" before them
  
        questionTextAnswerOptionsContent.push(
          new Paragraph({
            children: [new TextRun({ text: "Answer Columns:", bold: true })],
          })
        );
      }
    questionTextField?.answerColList
      ?.sort((a, b) => a.sequence - b.sequence)
      ?.forEach((answerCol) => {
        // adds answerRows content for each question
        questionTextAnswerOptionsContent.push(
          new Paragraph({ text: `${answerCol.code}.  ${answerCol.text}` })
        );
      });
    return questionTextAnswerOptionsContent;
  });
  return questionTextsContent;
};

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} customQuestions Array containing all local questions for the wave.
 * @description The function generates content related to wave local questions and returns the list in doc format.
 */
const buildCustomQuestionDocxContent = (customQuestions) => {
  const customQns = customQuestions.map((customQuestion) => {
    return {
      customNamePrefix: customQuestion.customNamePrefix ?? "",
      customQuestionName: customQuestion.question?.name,
      customQuestionStatus: customQuestion.customQuestionStatus.status,
      isLegalApproved: customQuestion.isLegalApproved
        ? "Question and translations have been legally approved"
        : "Question and translations have not been legally approved",
      variableCode: customQuestion.question.variableCode,
      questionType: customQuestion.question.questionType?.name,
      base: customQuestion.question.base,
      scriptingInstruction: customQuestion.scriptingInstruction,
      dpInstructions: customQuestion.dpInstructions,
      questionTextFields: customQuestion.question?.questionTextFields?.map(
        (qtf) => {
          return {
            questionText: qtf.questionText,
            questionAnswer: qtf.answerText,
            language: qtf.language.name,
            isTranslationApproved: qtf.isTranslationApproved
              ? "Translation has been approved."
              : "Translation has not been approved.",
          };
        }
      ),
    };
  });


  const customQnDocxContent = [];
  customQns.forEach((customQn, index) => {
    const customQnName = new Paragraph({
      children: [
        new TextRun({
            text: `${index + 1}. ${GetFormattedLocalQuestionName(customQn?.customNamePrefix, customQn.customQuestionName)}`,
            size: 28,
        }),
      ],
      heading: HeadingLevel.HEADING_4,
    });

    const status = getStatus(customQn.customQuestionStatus);
    const customQnStatus = new Paragraph({
      children: [
        new TextRun({
          text: `Question status: ${status}`,
          bold: true,
        }),
      ],
    });
    const customQnsTextFields = buildLocalQuestionTextFieldContent(customQn.questionTextFields);
    const customQnsVarCode = new Paragraph({
      children: [
        new TextRun({ text: "Variable code: ", bold: true }),
        new TextRun({
          text:
            customQn.variableCode &&
            `${customQn.variableCode?.replace(/<[^>]*>/g, "")}`,
        }),
      ],
    });
    const customQnsType = new Paragraph({
      children: [
        new TextRun({ text: "Question type: ", bold: true }),
        new TextRun({
          text:
            customQn.questionType &&
            `${customQn.questionType?.replace(/<[^>]*>/g, "")}`,
        }),
      ],
    });

    const customQnsBase = new Paragraph({
      children: [
        new TextRun({ text: "Base: ", bold: true }),
        new TextRun({
          text: customQn.base && `${customQn.base?.replace(/<[^>]*>/g, "")}`,
        }),
      ],
    });

    const customQnsScriptingInst = new Paragraph({
      children: [
        new TextRun({ text: "Scripting instructions: ", bold: true }),
        new TextRun({
          text:
            customQn.scriptingInstruction &&
            `${customQn.scriptingInstruction?.replace(/<[^>]*>/g, "")}`,
        }),
      ],
    });
    const customDPTables = buildLocalQuestionDPInstructionsContent(customQn.dpInstructions);

    let customQnsLegalApproved = new Paragraph({
      text: customQn.isLegalApproved,
    });

    customQnDocxContent.push(
      emptyLine,
      customQnName,
      customQnStatus,
      emptyLine,
      customQnsVarCode,
      customQnsType,
      customQnsBase
    );

    customQnsTextFields.forEach((questionTextFieldQuesAns) => {
      customQnDocxContent.push(emptyLine, ...questionTextFieldQuesAns);
    });

    customQnDocxContent.push(
      emptyLine,
      customQnsScriptingInst,
      emptyLine,
      new Paragraph({
        children: [
          new TextRun({
            text: "DP Table Instructions",
            bold: true,
          }),
        ],
      })
    );

    customDPTables.forEach((dpInstructions) => {
      customQnDocxContent.push(...dpInstructions);
    });

    customQnDocxContent.push(emptyLine, customQnsLegalApproved);
  });

  // Adds Local Questions into the variable that will be used to render the document
  const localQuestionsContent = {
    children: [
      customQuestions.length > 0 &&
        new Paragraph({
          children: [
            new TextRun({
              text: "Local Questions",
              size: 64,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
      ...customQnDocxContent,
    ],
  };
  return localQuestionsContent;
};
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} questionTextFields Array containing all answer text and answer options for the local question.
 * @description The function generates content related to local questions answer options and returns the list in doc format.
 */
const buildLocalQuestionTextFieldContent = (questionTextFields) => {
  const questionTextFieldsContent = questionTextFields.map(
    (questionTextField) => {
      return [
        new Paragraph({
          children: [
            new TextRun({ text: "Question text", bold: true }),
            questionTextField.language
              ? new TextRun({
                  text: ` - ${questionTextField.language}:`,
                  bold: true,
                })
              : new TextRun({
                  text: ":",
                  bold: true,
                }),
            new TextRun({
              text: ` ${questionTextField.questionText.replace(
                /<[^>]*>/g,
                ""
              )}`,
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({ text: "Answer: ", bold: true }),
            new TextRun({
              text: `${questionTextField?.questionAnswer?.replace(
                /<[^>]*>/g,
                ""
              )}`,
            }),
          ],
        }),
        new Paragraph({
          text: questionTextField.isTranslationApproved,
        }),
      ];
    }
  );
  return questionTextFieldsContent;
};
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} dpInstructions Array containing all DP table instructions for the local question.
 * @description The function generates content related to local questions DP table instructions and returns the list in doc format.
 */
const buildLocalQuestionDPInstructionsContent = (dpInstructions) => {
  const dpInstructionsContent = dpInstructions?.map(
    (dpInstruction, index) => {
      return [
        emptyLine,
        new Paragraph({
          children: [
            new TextRun({
              text: `${"Instructions Table " + (index + 1) + ":"}`,
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({ text: "Question Name: ", bold: true }),
            new TextRun({
              text:
                dpInstruction?.tableName &&
                `${dpInstruction?.tableName.replace(/<[^>]*>/g, "")}`,
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({ text: "Short Title: ", bold: true }),
            new TextRun({
              text:
                dpInstruction?.tableTitle &&
                `${dpInstruction?.tableTitle.replace(/<[^>]*>/g, "")}`,
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({ text: "Base / Filter: ", bold: true }),
            new TextRun({
              text:
                dpInstruction?.tableBase &&
                `${dpInstruction?.tableBase.replace(/<[^>]*>/g, "")}`,
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({ text: "Instructions: ", bold: true }),
            new TextRun({
              text:
                dpInstruction?.dpInstruction &&
                `${dpInstruction?.dpInstruction.replace(/<[^>]*>/g, "")}`,
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({ text: "Additional Information: ", bold: true }),
            new TextRun({
              text:
                dpInstruction?.dpAdditionalInformation &&
                `${dpInstruction?.dpAdditionalInformation.replace(
                  /<[^>]*>/g,
                  ""
                )}`,
            }),
          ],
        }),
      ];
    }
  );
  return dpInstructionsContent;
};
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Object} doc Document object containing the contents of the file that will be saved.
 * @param {String} fileName Name of the file that will be saved.
 *
 * @description This function accepts a Document object and a file name as arguments, and based on them it creates and downloads a .docx file.
 */
const saveDocumentToFile = (doc, fileName) => {
  // Create a mime type that will associate the new file with Microsoft Word
  const mimeType =
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  // Create a Blob containing the Document instance and the mimeType
  Packer.toBlob(doc).then((blob) => {
    const docblob = blob.slice(0, blob.size, mimeType);
    // Save the file using saveAs from the file-saver package
    saveAs(docblob, fileName);
  });
};

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} selectedModules Array containing all modules that have been selected for this wave.
 * @param {Array} customQuestions Array containing all local questions for this wave.
 * @param {Boolean} isCountrySpecificQuestionsDetected Boolean for if a wave contains country specific questions.
 * @param {Object} wave Object containing the current wave in the store.
 *
 * @description This function builds the page contents of the .docx file that will be generated ( using the function 'buildDocxPageContents' ), creates a new 'Document' and assigns the created page contents to it. After building the content and assigning it to the document it saves the file as a .docx with the name being the name of the wave using the function 'saveDocumentToFile'.
 */
export const generateWordDocument = (
  selectedModules,
  waveName,
  incomingCustomQuestions,
  incomingIsCountrySpecificQuestionsDetected,
  isDownloadAll
) => {
  let customQuestions = incomingCustomQuestions || [];
  let isCountrySpecificQuestionsDetected =
    incomingIsCountrySpecificQuestionsDetected || false;

  const downloadPreviewDocxContent = buildDocxPageContents(
    selectedModules,
    customQuestions,
    isCountrySpecificQuestionsDetected
  );

  let doc = new Document({
    sections: downloadPreviewDocxContent,
  });
  saveDocumentToFile(doc, `${waveName}.docx`);
};
