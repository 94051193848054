import React from 'react';
import Button from '../../ui/buttons/Button';
import styles from './ConfirmNoCustomQ.module.css';

/**
 * @component ConfirmNoCustomQ
 * @param {Function} handleApprove Function called when the user clicks on 'Submit'. After executing the function 'handleClose' is also called.
 * @param {Function} handleClose Function called when the user clicks the 'Cancel' button. Triggered for both the 'Submit' and 'Cancel' button when they are clicked.
 * @param {Boolean} noLocalQ Boolean for if the module selection contains any custom questions.
 * @description This components serves as the content props passed down to the ModalDialog component, and is rendered when the user clciks on 'Submit final selection' on the module selection page. Depending on if there are local questions for this wave, it will:
 *
 *  - Inform the user that they do not have any local questions created for this wave, and ask them to confirm if they want to move forward and submit the final selection.
 *  - Inform the user that they are about to submit their final selection, ask them to confirm if they want to move forward and submit the final selection.
 *
 * If the user clicks on 'Cancel' the modal will be hidden, and if they click on 'Submit' it will call the 'handleApprove' and 'handleClose' functions consecutively, submitting their final selection for the wave, and closing the modal window.
 *
 * This component is a child of the ModuleSelection component.
 *
 * @returns {JSX} An element that is used as the content of a ModalDialog component. The component warns the user that they are about to submit their final module selection and prompts to choose if they want to go ahead or not with saving the module selection.
 */
const ConfirmNoCustomQ = ({
  id,
  handleApprove,
  handleClose,
  noLocalQ,
  changesToSave,
}) => {

    const alertMessage = changesToSave
            ? `You are about to submit your <b>final</b> module selection with <b style={{color="#de350b"}}>unsaved changes</b> in module selection. These changes <b>will not be saved!<b> Are you sure you want to continue?`
            : 'You are about to submit your <b>final</b> module selection. Are you sure you want to continue?';
  return (
    <div>
      <div className={styles.Close}>
      <Button type={'close'} handleOnClick={() => handleClose()} />
      </div>
      <div className={styles.Title}>
        <h4>Final selection warning</h4>
      </div>
      <div className={styles.Message}>
        <div
                  dangerouslySetInnerHTML={{
                      __html: noLocalQ
                          ? 'You are about to submit your <b>final</b> module selection <b>without adding any local questions</b>. Are you sure you want to continue?'
                          : alertMessage,
          }}
        ></div>
      </div>
      <div className={styles.btnContainer}>
        <Button handleOnClick={() => handleClose()} type="secondary">
          Cancel
        </Button>
        <Button
          handleOnClick={() => {
            handleApprove();
            handleClose();
          }}
          type="primary"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ConfirmNoCustomQ;
