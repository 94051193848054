/**
 * @function
 * @memberof App
 * @param {Object} modules Object containing key value pairs, where each key is equal to a module's 'surveyModuleId' property, and the value is an object containing all details pertaining to the module.
 * @param {Object} customQuestions Object containing key value pairs, where each key is equal to a custom question's 'customQuestionId' property, and the value is an object containing all details pertaining to the custom question.
 *
 * @description The function performs the following actions to calculate the length of interview for the Module Selection process:
 *
 * * Calculate the total length of interview for all selected modules by going over each module and adding the value of their 'loiContributionInMinutes' to the total, stored in the variable 'selectedModules'.
 * * Calculate the total length of interview for all custom questions by going over each custom question and adding the value of their 'loiContributionInMinutes' to the total, stored in the variable 'customQuestion'.
 * * Add the values of 'selectedModules' and 'customQuestion' together and rounds it to one decimal point, and returns the value.
 *
 * @returns {Number} The total calculated length of interview.
 */
export const calcloi = (modules, customQuestions) => {
  let loi = 0;
  const selectedModules = Object.values(modules)
    .filter((module) => module.selected)
    .reduce(
      (a, b) => a?.loiContributionInMinutes || a + b.loiContributionInMinutes,
      0
    );

  const customQuestion = Object.values(customQuestions).reduce(
    (a, b) => a?.loiContributionInMinutes || a + b.loiContributionInMinutes,
    0
  );

  loi = Math.round((selectedModules + customQuestion) * 10) / 10;

  return loi;
};
