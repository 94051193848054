import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep as _cloneDeep } from 'lodash';

import styles from './DisplayAnswers.module.css';

/**
 * @component DisplayAnswers
 * @param {Number} id - The unique identifier for the component.
 * @param {Array} answers - An array of answer objects to display.
 * @param {String} subtitle - An optional subtitle to display above the answer list.
 *
 * @description A component for displaying a list of answers with the option to expand or collapse the list.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const answers = [
 *   { sequence: 1, code: 'A1', text: 'Answer 1' },
 *   { sequence: 2, code: 'A2', text: 'Answer 2' },
 *   { sequence: 3, code: 'A3', text: 'Answer 3' },
 *   // Add more answers as needed
 * ];
 *
 * return (
 *   <DisplayAnswers
 *     id={1}
 *     answers={answers}
 *     subtitle="Sample Subtitle"
 *   />
 * );
 */

const DisplayAnswers = ({ id, answers, subtitle, isPreview }) => {
  const [expandList, setExpandList] = useState(false);
  const [orderedAnswers, setOrderedAnswers] = useState([...answers]);

  /**
   * @method useEffect
   * @memberof DisplayAnswers
   *
   * @description Sorts the answers based on their sequence when the 'answers' prop changes.
   */
  useEffect(() => {
    let sortAnswerList = _cloneDeep(answers);
    sortAnswerList.sort((a, b) => {
      return a.sequence - b.sequence;
    });

    setOrderedAnswers(sortAnswerList);
  }, [answers]);

  return (
    <>
      {
        <div>
          {<div className={styles.contentBold}>{subtitle}</div>}
          <ul key={id} className={styles.AnswerList}>
            {orderedAnswers.map((ans, ind) => {
              return (
                <li
                  key={ans.sequence}
                  className={`${
                    !isPreview &&
                    !expandList &&
                    ind > 9 &&
                    orderedAnswers.length > 15
                      ? styles.HideListItem
                      : styles.ShowListItem
                  }`}
                >
                  <div className={styles.AnswerCode}>{ans.code}.</div>
                  <div className={styles.AnswerText}>{ans.text}</div>
                </li>
              );
            })}
            {!isPreview && !expandList && orderedAnswers.length > 15 && (
              <>
                <li>...</li>
                <li
                  onClick={() => setExpandList(true)}
                  className={styles.ExpandToggle}
                >
                  Show more...
                </li>
              </>
            )}
            {!isPreview && expandList && orderedAnswers.length > 15 && (
              <li
                onClick={() => setExpandList(false)}
                className={styles.ExpandToggle}
              >
                Show less...
              </li>
            )}
          </ul>
        </div>
      }
    </>
  );
};

DisplayAnswers.propTypes = {
  answers: PropTypes.any,
  id: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
};

DisplayAnswers.defaultProps = {
  id: -1,
  subtitle: 'default',
};

export default DisplayAnswers;
