import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";

import "./Notification.css";

let toastInstance;

export const initializeToast = (ref) => {
  toastInstance = ref;
};

export const showToast = (children, autoClose) => {
  const toastData = { title: children.title, content: children.message };
  toastData.timeOut = autoClose ? 5000 : 0;
  if (toastInstance) {
    toastInstance.show(toastData);
  }
};
function onbeforeOpen(e) {
  e.cancel = preventDuplicate(e);
}
function preventDuplicate(e) {
  let toastEle = e.element;
  let toasts = e.toastObj.element.children;
  for (const toast of toasts) {
    let toastMessage = toast.querySelector(".e-toast-message");
    if (toastMessage?.isEqualNode(toastEle.querySelector(".e-toast-message"))) {
      return true;
    }
  }
  return false;
}
function Notification() {
  const container = document.createElement("div");

  const renderToastContainer = () => {
    ReactDOM.render(
      <ToastComponent
        ref={(toast) => initializeToast(toast)}
        showCloseButton={true}
        showProgressBar={true}
        position={{ X: "Center", Y: "Bottom" }}
        width={500}
        beforeOpen={onbeforeOpen}
      />,
      container
    );
  };

  useEffect(() => {
    renderToastContainer();

    return () => {
      ReactDOM.unmountComponentAtNode(container);
    };
  });

  return null;
}
export default Notification;

Notification.propTypes = {
  autoClose: PropTypes.bool,
  children: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string
  })
};
