import React, { useEffect, useState } from 'react';
import styles from './Tooltip.module.css';

/**
 * @component Tooltip
 * @param {String} content - The content to be displayed in the tooltip.
 * @param {'Ok' | 'Warning' | 'Error'} icon - The type of icon to be displayed alongside the tooltip.
 *
 * @description A tooltip component that displays additional information on hover.
 *
 * @returns {JSX.Element}
 *
 * @example
 * return (
 *   <Tooltip
 *     content="This is an information tooltip."
 *     icon="Ok"
 *   />
 * );
 */
export const Tooltip = ({ content, icon }) => {
  const [showTooltipContent, setShowTooltipContent] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(content);
  const [tooltipIcon, setTooltipIcon] = useState(icon);

  /**
@method useEffect - 1st instance  
   * @memberof Tooltip
   *
   * @description Updates the tooltip content when the 'content' prop changes.
   */
  useEffect(() => {
    setTooltipContent(content);
  }, [content]);

  /**
@method useEffect - 2nd instance  
   * @memberof Tooltip
   *
   * @description Updates the tooltip icon when the 'icon' prop changes.
   */
  useEffect(() => {
    setTooltipIcon(icon);
  }, [icon]);

  return (
    <div
      className={`${styles.Icon} ${styles[tooltipIcon]}`}
      onMouseEnter={() => {
        if (content.length > 0) {
          setShowTooltipContent(true);
        }
      }}
      onMouseLeave={() => setShowTooltipContent(false)}
    >
      {content && showTooltipContent && (
        <div className={styles.Tooltip}>
          <div dangerouslySetInnerHTML={{ __html: tooltipContent }}></div>
          <div className={styles.TooltipCorner} />
        </div>
      )}
    </div>
  );
};
