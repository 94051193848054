import * as actionTypes from './actionTypes';
import axios from '../../axiosInstance';
import { sortCountryWaveProcesses } from './wave.utility';
// get wave for languages
export const GetWaveStart = () => {
  return {
    type: actionTypes.GET_WAVE_START,
  };
};

export const ClearWave = () => {
  return {
    type: actionTypes.CLEAR_WAVE_DATA,
  };
};

export const GetWaveFail = (error) => {
  return {
    type: actionTypes.GET_WAVE_FAIL,
    error: error.message,
  };
};

export const GetWaveSuccess = (wave) => {
  return {
    type: actionTypes.GET_WAVE_SUCCESS,
    wave: wave,
  };
};

// get all countries and waves for statusgrid
export const GetCountriesWavesStart = () => {
  return {
    type: actionTypes.GET_COUNTRIES_WAVES_START,
  };
};

export const GetCountriesWavesFail = (error) => {
  return {
    type: actionTypes.GET_COUNTRIES_WAVES_FAIL,
    error: error.message,
  };
};

export const GetCountriesWavesSuccess = (countries) => {
  return {
    type: actionTypes.GET_COUNTRIES_WAVES_SUCCESS,
    countries: countries,
  };
};

export const updateWaveCurrentStatus = (currentProcess) => {
  return {
    type: actionTypes.UPDATE_WAVE_STATUS,
    name: currentProcess.name,
    id: currentProcess.id,
    status: currentProcess.status,
  };
};

export const CreateWaveStart = () => {
  return {
    type: actionTypes.CREATE_WAVE_START,
  };
};

export const CreateWaveSuccess = (wave) => {
  return {
    type: actionTypes.CREATE_WAVE_SUCCESS,
    wave: wave,
    id: wave.waveId,
  };
};

export const CreateWaveFail = (err) => {
  return {
    type: actionTypes.CREATE_WAVE_FAIL,
    error: err,
  };
};

export const UpdateWaveStart = () => {
  return {
    type: actionTypes.UPDATE_WAVE_START,
  };
};

export const UpdateWaveSuccess = (wave) => {
  return {
    type: actionTypes.UPDATE_WAVE_SUCCESS,
    wave: wave,
  };
};
export const UpdateWaveClientModuleSlectionLockedStart = () => {
  return {
    type: actionTypes.UPDATE_WAVE_CLIENTSELECTIONLOCKED_STATUS_START,
  };
};
export const UpdateWaveClientModuleSlectionLockedSuccess = (clientSelectionLocked) => {
  return {
    type: actionTypes.UPDATE_WAVE_CLIENTSELECTIONLOCKED_STATUS_SUCCESS,
    clientSelectionLocked: clientSelectionLocked,
  };
};
export const UpdateWaveClientModuleSlectionLockedFail = (err) => {
  return {
    type: actionTypes.UPDATE_WAVE_CLIENTSELECTIONLOCKED_STATUS_FAIL,
    error: err,
  };
};
export const UpdateWaveFail = (err) => {
  return {
    type: actionTypes.UPDATE_WAVE_FAIL,
    error: err,
  };
};

export const DeleteWaveStart = () => {
  return {
    type: actionTypes.DELETE_WAVE_START,
  };
};

export const DeleteWaveFail = (err) => {
  return {
    type: actionTypes.DELETE_WAVE_FAIL,
    error: err,
  };
};

export const DeleteWaveSuccess = (waveId, countryId) => {
  return {
    type: actionTypes.DELETE_WAVE_SUCCESS,
    payload : {
      waveId: waveId,
      countryId: countryId
    },
  };
};

export const DeleteWaveProcessStart = (id) => {
  return {
    type: actionTypes.DELETE_WAVE_PROCESS,
    id: id,
  };
};

export const GetProcessesStart = () => {
  return {
    type: actionTypes.GET_PROCESSES_START,
  };
};

export const GetProcessesFail = (error) => {
  return {
    type: actionTypes.GET_PROCESSES_FAIL,
    error: error.message,
  };
};

export const GetProcessesSuccess = (processes) => {
  return {
    type: actionTypes.GET_PROCESSES_SUCCESS,
    processes: processes,
  };
};

export const CopyWaveStart = () => {
  return {
    type: actionTypes.COPY_WAVE_START,
  };
};

export const CopyWaveSuccess = (wave) => {
  return {
    type: actionTypes.COPY_WAVE_SUCCESS,
    wave: wave,
  };
};

export const GetWaveStatusesStart = () => {
  return {
    type: actionTypes.GET_WAVESTATUSES_START,
  };
};

export const GetWaveStatusesFail = (error) => {
  return {
    type: actionTypes.GET_WAVESTATUSES_FAIL,
    error: error.message,
  };
};

export const GetWaveStatusesSuccess = (statuses) => {
  return {
    type: actionTypes.GET_WAVESTATUSES_SUCCESS,
    waveStatuses: statuses,
  };
};
export const UnlockWaveModuleSelection = (waveData,waveId) => {
  return (dispatch) => {
    dispatch(UpdateWaveStart());
    axios        
        .post(`Waves/${waveId}/unlockWaveModuleSelections`, waveData)        
      .then((res) => {
        const newWaveData = res.data;
        dispatch(UpdateWaveSuccess(newWaveData));
      })
      .then(() => {
        dispatch(GetCountriesWaves());
      })
      .catch((err) => {
        dispatch(UpdateWaveFail(err));
      });
  };
};

export const UnlockWaveClientModuleSelection = (waveId, clientSelectionLocked) => {
  return (dispatch) => {
    dispatch(UpdateWaveClientModuleSlectionLockedStart());
    axios        
      .put(`Waves/${waveId}/UnlockWaveClientModuleSelection/${clientSelectionLocked}`)        
      .then((res) => {
        const newWaveData = res.data;
        dispatch(UpdateWaveClientModuleSlectionLockedSuccess(newWaveData));
      })
      .catch((err) => {
        dispatch(UpdateWaveClientModuleSlectionLockedFail(err));
      });
  };
};

export const GetWave = (waveId) => {
  return (dispatch) => {
    dispatch(GetWaveStart());
    axios
      .get(`Waves/${waveId}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        const wave = res.data;
        dispatch(GetWaveSuccess(wave));
      })
      .catch((error) => {
        dispatch(GetWaveFail(error));
      });
  };
};

export const GetCountriesWaves = () => {
  return (dispatch) => {
    dispatch(GetCountriesWavesStart());
    axios
      .get(`Countries/Waves`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        dispatch(GetCountriesWavesSuccess(sortCountryWaveProcesses(res.data)));
      })
      .catch((error) => {
        dispatch(GetCountriesWavesFail(error));
      });
  };
};

export const CreateWave = (waveData, SendNotification, notificationType, countryId, IsWaveProcessModuleSelectionSetToInProgress) => {
  return (dispatch) => {
    dispatch(CreateWaveStart());
    axios
      .post('Waves', waveData)
      .then((res) => {
        let newWaveData = res.data;
        dispatch(CreateWaveSuccess(newWaveData));
        if(IsWaveProcessModuleSelectionSetToInProgress) {
          dispatch(SendNotification(notificationType,countryId,newWaveData.waveId));
        }
      })
      .then(() => {
        dispatch(GetCountriesWaves());
      })
      .catch((err) => {
        dispatch(CreateWaveFail(err));
      });
  };
};

export const UpdateWave = (waveData) => {
  return (dispatch) => {
    dispatch(UpdateWaveStart());
    axios
      .put(`Waves`, waveData)
      .then((res) => {
        let newWaveData = res.data;
        console.log('UPDATE WAVE RESPONSE');
        dispatch(UpdateWaveSuccess(newWaveData));
      })
      .then(() => {
        dispatch(GetCountriesWaves());
      })
      .catch((err) => {
        dispatch(UpdateWaveFail(err));
      });
  };
};

export const DeleteWave = (waveId, countryId) => {
  return(dispatch) => {
    dispatch(DeleteWaveStart())
    axios
      .delete(`Waves/${waveId}`)
      .then((res) => {
        dispatch(DeleteWaveSuccess(waveId, countryId))
      })
      .catch((err) => {
        dispatch(DeleteWaveFail(err));
      });
  };
};

export const DeleteWaveProcess = (processId) => {
  return (dispatch) => {
    dispatch(DeleteWaveProcessStart());
    axios
      .delete(`WaveProcesses/${processId}`)
      .then((res) => {
        console.log(res, 'deleteprocesses');
      })
      .catch((err) => {
        console.log(err, 'error in deleteprocess');
      });
  };
};

export const GetProcesses = () => {
  return (dispatch) => {
    dispatch(GetProcessesStart());
    axios
      .get('/Processes')
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        const processes = res.data;
        dispatch(GetProcessesSuccess(processes));
      })
      .catch((error) => {
        dispatch(GetProcessesFail(error));
      });
  };
};

export const CopyWave = (targetWaveId, sourceWaveId, waveData) => {
  return (dispatch) => {
    dispatch(CopyWaveStart()); //todo
    axios
      .put(`Waves/${targetWaveId}/CopyOf/${sourceWaveId}`)
      .then((res) => {
        dispatch(CopyWaveSuccess(res.data)); //todo
      })
      .then((res) => {
        UpdateWave(waveData);
      })
      .catch((error) => {
        console.log(error, 'copy wave failed');
      });
  };
};
export const GetWaveStatuses = () => {
  return (dispatch) => {
    dispatch(GetWaveStatusesStart());
    axios
      .get('/WaveStatuses')
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        const statuses = res.data;
        dispatch(GetWaveStatusesSuccess(statuses));
      })
      .catch((error) => {
        dispatch(GetWaveStatusesFail(error));
      });
  };
};
