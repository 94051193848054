import React, {  useState } from "react";
import Button from "../buttons/Button";
import styles from './ModuleGroupPopup.module.css'
import Checkbox from "../checkbox/Checkbox";

function ModuleGroupPopup ({handleClose, groupModules, handleSave, handleRecursiveCheck}) {
  const [selectedModules, setSelectedModules] = useState([])
  
  const handleClick = (action) =>{
    switch (action) {
      case 'close':
        handleClose(groupModules.activeModuleId)
        break;
      case 'cancel':
        handleClose(groupModules.activeModuleId)
        break;
      case 'save':
        if(groupModules.isRecursiveCheck){
          handleRecursiveCheck(selectedModules)
        }else{
          handleSave(selectedModules)
        }        
        break
      default:
        break;
    }
  }

  const handleCheck = (id, checked) => {
    if (!checked && selectedModules.includes(id)){
      const moduleIndex = selectedModules.indexOf(id)
      let newVal = [...selectedModules]
      newVal.splice(moduleIndex, 1)
      setSelectedModules([...newVal])
      return;
    }
    if (checked){
      setSelectedModules(oldState => [...oldState, id])
    } 
  }

  
 return(
    <div>      
      <div className={styles.BtnCloseWrapper}>
        <Button handleOnClick={()=>handleClick('close')} type='close' />
      </div>
      <div className={styles.Title}>
        <h4>Dependency Choice module rule</h4>
      </div>
      <div  className={styles.Message}>Module <b>{groupModules.activeModuleName}</b> is part of a group. To continue please select at least one of the modules below.</div>  

      <div className={styles.ModuleList}>
        {groupModules.dependentModules.map(module => {
            if (module.moduleId !== groupModules.activeModuleId) {
                return (
                    <Checkbox key={module.moduleId} id={module.moduleId} label={module.name} checked={selectedModules.includes(module.moduleId)} changed={(checked, id) => handleCheck(id, checked)} />
                )
            }
            else return null;
        })}
      </div>

      <div className={styles.BtnWrapper}>
        <Button handleOnClick={()=>handleClick('cancel')} type='secondary' >Cancel</Button>
        <Button handleOnClick={()=>handleClick('save')} type='primary' disabled={selectedModules.length>0?false:true} >Save</Button>
      </div>
      
    </div>
 )
}

export default ModuleGroupPopup