import React from 'react';
import styles from './Dropdown.module.css';

/**
 * @component Dropdown
 * @param {String} id - The unique identifier for the Dropdown component.
 * @param {String} label - The label to display above the dropdown.
 * @param {String} value - The currently selected value in the dropdown.
 * @param {Array} options - An array of objects representing the dropdown options.
 * @param {Function} onChange - The callback function to execute when the selected value changes.
 * @param {String} customStyle - Additional custom CSS classes for styling the dropdown.
 * @param {String} placeholder - A placeholder text to display as the initial option in the dropdown.
 *
 * @description Renders a dropdown component allowing users to select from a list of options.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleDropdownChange = (event) => {
 *   console.log(`Selected value: ${event.target.value}`);
 * };
 *
 * const options = [
 *   { value: 'option1', label: 'Option 1' },
 *   { value: 'option2', label: 'Option 2' },
 *   { value: 'option3', label: 'Option 3' }
 * ];
 *
 * return (
 *   <Dropdown
 *     id="dropdown-1"
 *     label="Choose an option"
 *     value="option1"
 *     options={options}
 *     onChange={handleDropdownChange}
 *     customStyle="custom-dropdown"
 *     placeholder="Select an option"
 *   />
 * );
 */
const Dropdown = ({
  id,
  label,
  value,
  options,
  onChange,
  customStyle,
  placeholder,
  name,
  shouldHidePlaceholder,
}) => {
  return (
    <div className={`${customStyle} ${styles.Dropdown}`} key={id}>
      <label>{label}</label>
      <select value={value} onChange={onChange} name={name}>
        <option
          value={undefined}
          disabled={shouldHidePlaceholder}
          hidden={shouldHidePlaceholder}
        >
          {placeholder || 'Select an option'}
        </option>
        {options?.map((option) => (
            <option key={option.value} value={option.value} data-testid={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Dropdown;
