import * as actionTypes from './actionTypes';
import axios from '../../axiosInstance';
import { updateCustomQuestion } from '../../utility/utility';

export const GetCustomQuestionsStart = () => {
  return {
    type: actionTypes.GET_CUSTOM_QUESTIONS_START,
  };
};

export const GetCustomQuestionsFail = (err) => {
  return {
    type: actionTypes.GET_CUSTOM_QUESTIONS_FAIL,
    error: err.message,
  };
};

export const GetCustomQuestionsSuccess = (questionArr) => {
  return {
    type: actionTypes.GET_CUSTOM_QUESTIONS_SUCCESS,
    customQuestions: questionArr,
  };
};
export const GetCustomQuestionTypesSuccess = (questionTypesArr) => {
  return {
    type: actionTypes.GET_CUSTOM_QUESTION_TYPES_SUCCESS,
    customQuestionTypes: questionTypesArr,
  };
};
export const GetCustomQuestionTypesFail = (err) => {
  return {
    type: actionTypes.GET_CUSTOM_QUESTION_TYPES_FAIL,
    error: err.message,
  };
};

export const CreateCustomQuestionStart = () => {
  return {
    type: actionTypes.CREATE_CUSTOM_QUESTION_START,
  };
};

export const CreateCustomQuestionFail = (err) => {
  return {
    type: actionTypes.CREATE_CUSTOM_QUESTION_FAIL,
    error: err,
  };
};

export const CreateCustomQuestionSuccess = (customQuestion, id) => {
  return {
    type: actionTypes.CREATE_CUSTOM_QUESTION_SUCCESS,
    customQuestion: customQuestion,
    id: id,
  };
};

export const UpdateCustomQuestionStart = () => {
  return {
    type: actionTypes.UPDATE_CUSTOM_QUESTION_START,
  };
};

export const UpdateCustomQuestionFail = (err) => {
  return {
    type: actionTypes.UPDATE_CUSTOM_QUESTION_FAIL,
    error: err,
  };
};

export const UpdateCustomQuestionSuccess = (customQuestion) => {
  return {
    type: actionTypes.UPDATE_CUSTOM_QUESTION_SUCCESS,
    customQuestion: customQuestion,
  };
};

export const DeleteCustomQuestionStart = () => {
  return {
    type: actionTypes.DELETE_CUSTOM_QUESTION_START,
  };
};

export const DeleteCustomQuestionFail = (err) => {
  return {
    type: actionTypes.DELETE_CUSTOM_QUESTION_FAIL,
    error: err,
  };
};

export const DeleteCustomQuestionSuccess = (id) => {
  return {
    type: actionTypes.DELETE_CUSTOM_QUESTION_SUCCESS,
    id: id,
  };
};

// api calls

export const GetCustomQuestions = (waveId) => {
  return (dispatch) => {
    dispatch(GetCustomQuestionsStart());
    axios
      .get(`Waves/${waveId}/CustomQuestions`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        const newObj = res.data.reduce(
            (obj, item) => {
                obj[item.customQuestionId] = item; return obj
            },
          {}
        );
        dispatch(GetCustomQuestionsSuccess(newObj));
        dispatch(GetCustomQuestionTypes());
      })
      .catch((err) => {
        dispatch(GetCustomQuestionsFail(err));
      });
  };
};
export const GetCustomQuestionTypes = () => {
  return (dispatch) => {
    axios
      .get(`questionType`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        dispatch(GetCustomQuestionTypesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(GetCustomQuestionTypesFail(err));
      });
  };
};

export const CreateCustomQuestion = (waveId, questionProps, waveLanguages) => {
  let customQuestion = updateCustomQuestion(
    null,
    questionProps,
    waveId,
    waveLanguages,
  );
  return (dispatch) => {
    dispatch(CreateCustomQuestionStart());
    axios
      .post(`Waves/${waveId}/CustomQuestions`, customQuestion)
      .then((res) => {
        customQuestion = res.data;
        dispatch(
          CreateCustomQuestionSuccess(
            customQuestion,
            customQuestion.customQuestionId
          )
        );
      })
      .catch((err) => {
        dispatch(CreateCustomQuestionFail(err));
      });
  };
};



export const UpdateCustomQuestion = (customQuestion) => {
  return (dispatch) => {
    console.log('inside qctiontyp',customQuestion)
    dispatch(UpdateCustomQuestionStart());
    axios
      .put(`Waves/${customQuestion.waveId}/CustomQuestions`, customQuestion)
      .then((res) => {
        customQuestion = res.data;
        dispatch(UpdateCustomQuestionSuccess(customQuestion));
      })
      .catch((err) => {
        dispatch(UpdateCustomQuestionFail(err));
      });
  };
};

export const DeleteCustomQuestion = (waveId, customQuestionId) => {
  return (dispatch) => {
    dispatch(DeleteCustomQuestionStart());
    axios
      .delete(`Waves/${waveId}/CustomQuestions/${customQuestionId}`)
      .then((res) => {
        dispatch(DeleteCustomQuestionSuccess(customQuestionId));
      })
      .catch((err) => {
        dispatch(DeleteCustomQuestionFail(err));
      });
  };
};
