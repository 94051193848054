import * as actionTypes from '../actions/actionTypes'

const initialState = {
    demo: 0,
    loading: false,
    demoFetchRes: {}
};

const demoDispatch = (state, action) => {
    return {
        ...state,
        demo: action.payload
    }
}

const demoStart = (state, action) => {
    return {
        ...state,
        loading: true
    }
}

const demoSuccess = (state, action) => {
    return {
        ...state,
        demoFetchRes: {...action.waveData},
        loading: false
    }
}

const demoFail = (state, action) => {
    return {
        ...state,
        loading: false
    }
}


const demoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DEMO_ACTION: return demoDispatch(state, action);
        case actionTypes.DEMO_START: return demoStart(state, action);
        case actionTypes.DEMO_SUCCESS: return demoSuccess(state, action);
        case actionTypes.DEMO_FAIL: return demoFail(state, action);
        default: return state;
    }
};

export default demoReducer