import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

/**
 * @component Button
 * @param {String} id - The unique identifier for the button.
 * @param {Function} handleOnClick - The click event handler function.
 * @param {'default' | 'primary' | 'secondary' | 'selection' | 'bold' | 'close' | 'selectionMini' | 'listItem'} type - The type of button.
 * @param {Boolean} disabled - A flag indicating whether the button is disabled.
 * @param {Number} tabIndex - The tab index for keyboard navigation.
 * @param {String} children - The content to be displayed inside the button.
 * @description Renders a customizable button component with various styles and click event handling.
 * @returns {JSX.Element} A button element with specified properties and styles.
 * @example
 *
 * const handleButtonClick = (id) => {
 *   console.log(`Button clicked with id: ${id}`);
 * };
 *
 *  * return (
 *   <Button
 *     id="btn-1"
 *     handleOnClick={handleButtonClick}
 *     type="primary"
 *     disabled={false}
 *     tabIndex={0}
 *   >
 *     Click
 *   </Button>
 * );
 *  */
const Button = ({ id, handleOnClick, type, disabled, tabIndex, children,testId }) => {
  return (
    <button
      className={`${styles.btn} ${styles[type]}`}
      disabled={disabled}
      onClick={() => handleOnClick(id)}
      data-testid={testId}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleOnClick(id);
        }
      }}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleOnClick: PropTypes.func,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'selection',
    'bold',
    'close',
    'selectionMini',
    'listItem',
  ]).isRequired,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
};

Button.defaultProps = {
  id: '-1',
  handleOnClick: (id) => {
    return id;
  },
  type: 'default',
  disabled: false,
  tabIndex: 0,
};

export default Button;
