import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './TextInput.module.css';

/**
 * @component TextInput
 * @param {String} label - The label for the text input.
 * @param {String} placeholder - The placeholder text for the input.
 * @param {Boolean} required - Indicates whether the input is required.
 * @param {Boolean} disabled - Indicates whether the input is disabled.
 * @param {Boolean} autoFocus - Indicates whether the input should be focused on initially.
 * @param {String} id - The unique identifier for the input.
 * @param {String} value - The current value of the input.
 * @param {Boolean} invalid - Indicates whether the input is in an invalid state.
 * @param {String} error - The error message to display when the input is invalid.
 * @param {Function} onChange - A callback function to handle changes in the input's value.
 * @param {Array} children - An array of React elements that represent the label for the input.
 *
 * @description A customizable text input component.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleTextInputChange = (id, value) => {
 *   console.log(`Text input with id ${id} changed value to: ${value}`);
 * };
 *
 * return (
 *   <TextInput
 *     id="textinput-1"
 *     label="Text Input Label"
 *     placeholder="Enter your text here"
 *     required={true}
 *     disabled={false}
 *     autoFocus={true}
 *     value="Initial text"
 *     invalid={false}
 *     onChange={handleTextInputChange}
 *     error="This field is required"
 *   >
 *     Text Input Label
 *   </TextInput>
 * );
 */
const TextInput = ({
  label,
  placeholder,
  required,
  disabled,
  autoFocus,
  id,
  value,
  invalid,
  error,
  onChange,
  children,
}) => {
  const [inputValue, setInputValue] = useState(value || '');

  /**
   * @function change
   * @param {String} id - The unique identifier for the input.
   * @param {String} val - The new value of the input.
   *
   * @description Handles changes in the input's value and invokes the provided `onChange` callback.
   */
  const change = (id, val) => {
    setInputValue(val);
    onChange(id, val);
  };

  return (
    <>
      <label data-testid="input-label" className={classes.label}>
        {children}
      </label>
      <br />
      <input
        data-testid="text-input"
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        autoFocus={autoFocus}
        onChange={(event) => change(id, event.target.value)}
        value={inputValue}
        id={id}
        name={id}
        aria-label={label}
        aria-invalid={invalid}
        aria-errormessage="text-input-error"
        className={classes.textInput}
      />
      <br />
      {invalid ? (
        <p className={classes.textInputError} id="text-input-error">
          {error}
        </p>
      ) : null}
      <br />
    </>
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
};

TextInput.defaultProps = {
  label: 'textInputLabel',
  placeholder: '',
  required: false,
  disabled: false,
  invalid: false,
  onChange: (id, value) => {
    console.log(id, value);
  },
  error: '',
};

export default TextInput;
