import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './QuestionSubmit.module.css';
import Button from '../../ui/buttons/Button';
import {
  UpdateCustomQuestion,
  UpdateChangesToApprove,
} from '../../../store/actions';

// imports for syncfusion rich text editor
import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import {
  
  HtmlEditor,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import { updateCustomQuestion } from '../../../utility/utility';

/**
 * @component QuestionSubmit
 * @param {Number} customQuestionId - The ID of the custom question to be edited or submitted.

 * @description A React component that allows users to submit custom questions. Users can enter and edit question text and answers using a rich text editor. This component provides options for editing, saving, and previewing the custom question.
 *
 * @returns {JSX.Element}
 * 
  * @example
 * const customQuestionId = 1;
 * 
 * return (
 *   <Provider store={store}>
 *     <QuestionSubmit customQuestionId={customQuestionId} />
 *   </Provider>
 * );
 */
const QuestionSubmit = ({ customQuestionId }) => {
  const { customQuestions } = useSelector((store) => store.customQuestions);
  const dispatch = useDispatch();

  const [currentText, setCurrentText] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [customQuestion, setCustomQuestion] = useState({});
  const [readWrite, setReadWrite] = useState('write');

  /**
   * @function updateDataHandler
   * @memberof QuestionSubmit
   *
   * @description A function for handling the update of custom question data. This function updates the custom question object with new question text, answers, and a status of 'Requested'. It then updates the redux state with the modified custom question and to indicate that there are changes to approve. Finally, it sets the readWrite state to 'read' to disable further editing.
   */
  const updateDataHandler = () => {
    const newObj = updateCustomQuestion(customQuestion, {
      langSpec: {
        languageId: 9,
        questionText: currentText,
        answerText: currentAnswer,
      },
      status: 'Requested',
    });
    dispatch(UpdateCustomQuestion(newObj));
    dispatch(UpdateChangesToApprove(true));
    setReadWrite('read');
  };

  /**
   * @function
   * @memberof QuestionSubmit
   * @param {Event} e
   * @param {'text' | 'answer'} id String containing which field is being edited.
   *
   * @description Updates either the 'currentText' or 'currentAnswer' state variable, depending on the 'id' argument with the value of the event, 'e', argument.
   */
  const handleChange = (e, id) => {
    const requestValue = e.value;
    if (id === 'text') {
      setCurrentText(requestValue);
    } else if (id === 'answer') {
      setCurrentAnswer(requestValue);
    }
  };

  /**
   * @function
   * @memberof QuestionSubmit
   *
   * @description Sets the value of the 'readWrite' state variable to 'write'.
   */
  const handleEdit = () => {
    setReadWrite('write');
  };
  // To re-add the option to add images:
  // 1. Add 'Image' in the array below in the desired position.
  // 2. Replace both injects with the one commented below:
  // <Inject services={[Image, Toolbar, Link, HtmlEditor, QuickToolbar]} />

  const toolbarSettings = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      '|',
      'CreateLink',
      '|',
      'SourceCode',
      'Undo',
      'Redo',
    ],
  };

  /**
   * @method useEffect
   * @memberof QuestionSUbmit
   * @description The effect runs when the component mounts or when 'customQuestions' or 'customQuestionId' changes. It is responsible for initializing the component state  with data related to the custom question specified by customQuestionId.
   */
  useEffect(() => {
    if (
      Object.keys(customQuestions).length > 0 &&
      Object.keys(customQuestions).includes(customQuestionId.toString())
    ) {
      const myCustomQuestion = customQuestions[customQuestionId];
      setCustomQuestion(myCustomQuestion);
      const questionFields =
        myCustomQuestion.question.questionTextFields.filter(
          (lang) => lang.languageId === 9
        );
      if (questionFields?.length) {
        setCurrentText(questionFields[0].questionText);
        setCurrentAnswer(questionFields[0].answerText);
      }
      setReadWrite(
        myCustomQuestion.customQuestionStatus.status === 'New'
          ? 'write'
          : 'read'
      );
    }
  }, [customQuestions, customQuestionId]);

  return (
    <div className={styles.wrapper}>
      <div data-testid={'id-questionSubmit'} key={customQuestionId}>
        <h1>{customQuestion?.question?.name || ''}</h1>
        {readWrite === 'read' ? (
          <div className={styles.description}>
            <p className={styles.bold}>Question text</p>
            <p dangerouslySetInnerHTML={{ __html: currentText }}></p>
            <p className={styles.bold}>Question answers</p>
            <p dangerouslySetInnerHTML={{ __html: currentAnswer }}></p>
          </div>
        ) : (
          <div className={styles.description}>
            <p className={styles.bold}>Please add question text</p>
            <RichTextEditorComponent
              toolbarSettings={toolbarSettings}
              value={currentText}
              change={(e) => handleChange(e, 'text')}
            >
              <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>
            <p className={styles.bold}>Please add question answers</p>
            <RichTextEditorComponent
              toolbarSettings={toolbarSettings}
              value={currentAnswer}
              change={(e) => handleChange(e, 'answer')}
            >
              <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>
          </div>
        )}
      </div>
      <div className={styles.btnContainer}>
        {readWrite === 'read' ? (
          <Button
            type="primary"
            className={styles.btnPosRight}
            handleOnClick={() => handleEdit()}
          >
            Edit
          </Button>
        ) : (
          <Button
            type="primary"
            className={styles.btnPosRight}
            handleOnClick={() => updateDataHandler()}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestionSubmit;
