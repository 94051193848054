import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatusGrid.module.css';
import StatusGridItem from './StatusGridItem';

/**
 * @component StatusGrid
 * @param {Array} statusItems - An array of status items to display in the grid.
 * @param {String} reportinglink - The link to the survey's report.
 * @param {String} testlink - The test link for the wave.
 * @param {Function} statusToStatusType - A function that provides the item's status in a specific format ( which is then used for styling ).
 * @param {String} projectType - The type of the project associated with the status items.
 *
 * @description Renders a grid displaying status information for a project.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const statusItems = [
 *   { process: { name: 'Process 1' }, status: 'Complete', startDate: '2024-01-01', endDate: '2024-01-31' },
 *   { process: { name: 'Process 2' }, status: 'In Progress', startDate: '2024-02-01', endDate: '2024-02-28' }
 * ];
 *
 * const reportinglink = 'http://example.com/report';
 * const testlink = 'http://example.com/test';
 * const statusToStatusType = (status) => {
 *   const statusTypes = {
 *     'Complete': 'completeStatus',
 *     'In Progress': 'inProgressStatus',
 *     'Not Started': 'notStartedStatus'
 *   };
 *   return statusTypes[status] || 'defaultStatus';
 * };
 * const projectType = 'Sample Project';
 *
 * return (
 *   <StatusGrid
 *     statusItems={statusItems}
 *     reportinglink={reportinglink}
 *     testlink={testlink}
 *     statusToStatusType={statusToStatusType}
 *     projectType={projectType}
 *   />
 * );
 */
const StatusGrid = ({
  statusItems,
  reportinglink,
  testlink,
  statusToStatusType,
  projectType,
}) => {
  return (
    <div className={styles.container}>
      {/* header */}
      <div className={`${styles.cell} ${styles.title}`}>Process</div>
      <div className={`${styles.cell} ${styles.title}`}>Status</div>
      <div className={`${styles.cell} ${styles.title}`}>Start date</div>
      <div className={`${styles.cell} ${styles.title}`}>End date</div>

      {/* statusitems */}
      {statusItems.map((item) => {
        return (
            <StatusGridItem
            key={item.status}
            statusType={statusToStatusType(item.status)}
            {...item}
            projectType={projectType}
          />
        );
      })}

      {/* testlink */}
      <div className={`${styles.cell} ${styles.lastrow}`}>
        <span className={styles.label}>Test link:</span>
        <span className={styles.link}>
          <a href={testlink} target="_blank" rel="noreferrer" alt="testlink">
            {testlink}
          </a>
        </span>
      </div>

      {/* reportinglink */}
      <div className={`${styles.cell} ${styles.lastrow}`}>
        <span className={styles.label}>Final Report:</span>
        <span className={styles.link}>
          <a
            href={reportinglink}
            target="_blank"
            rel="noreferrer"
            alt="reportinglink"
          >
            {reportinglink}
          </a>
        </span>
      </div>
    </div>
  );
};

StatusGrid.propTypes = {
  statusItems: PropTypes.arrayOf(
    PropTypes.shape({
      process: PropTypes.shape({
        name: PropTypes.string,
      }),
      status: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    })
  ).isRequired,
  reportinglink: PropTypes.string.isRequired,
  testlink: PropTypes.string.isRequired,
  statusToStatusType: PropTypes.func.isRequired,
  projectType: PropTypes.string.isRequired,
};

StatusGrid.defaultProps = {
  statusItems: [
    {
      process: { name: 'default' },
      status: 'default',
      startDate: '2022-01-01',
      endDate: '2022-12-31',
    },
  ],
  reportinglink: 'http://defaultreport.com',
  testlink: 'http://defaulttest.com',
  statusToStatusType: (status) => `defaultStatus-${status}`,
  projectType: 'Default Project Type',
};

export default StatusGrid;
