import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './TextArea.module.css';

/**
 * @component TextArea
 * @param {String} placeholder - The placeholder text for the textarea.
 * @param {Boolean} required - Indicates whether the textarea is required.
 * @param {Boolean} disabled - Indicates whether the textarea is disabled.
 * @param {String} id - The unique identifier for the textarea.
 * @param {String} value - The current value of the textarea.
 * @param {Boolean} invalid - Indicates whether the textarea is in an invalid state.
 * @param {String} error - The error message to display when the textarea is invalid.
 * @param {Function} onChange - A callback function to handle changes in the textarea's value.
 * @param {Array} children - An array of React elements that represent the label for the textarea.
 *
 * @description A customizable textarea input component.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleTextAreaChange = (id, value) => {
 *   console.log(`Text area with id ${id} changed value to: ${value}`);
 * };
 *
 * return (
 *   <TextArea
 *     id="textarea-1"
 *     placeholder="Enter your text here"
 *     required={true}
 *     disabled={false}
 *     value="Initial text"
 *     invalid={false}
 *     onChange={handleTextAreaChange}
 *     error="This field is required"
 *   >
 *     Text Area Label
 *   </TextArea>
 * );
 */
const TextArea = ({
  placeholder,
  required,
  disabled,
  id,
  value,
  invalid,
  error,
  onChange,
  children,
  additionalStyling,
}) => {
  const [inputValue, setInputValue] = useState(value || '');

  /**
   * @function change
   * @memberof TextArea
   * @param {String} id - The unique identifier for the textarea.
   * @param {String} val - The new value of the textarea.
   *
   * @description Handles changes in the textarea's value and invokes the provided `onChange` callback.
   */
  const change = (id, val) => {
    setInputValue(val);
    onChange(id, val);
  };
  const getStyling = () => {
    let styleList = `${classes.textArea}`;
    if (additionalStyling?.length > 0) {
      additionalStyling.forEach((x) => {
        styleList += ` ${classes[x]}`;
      });
    }
    return styleList;
  };

  return (
    <>
      <label data-testid="input-label" className={classes.label}>
        {children}
      </label>
      <br />
      <textarea
        data-testid="text-area-input"
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={(event) => change(id, event.target.value)}
        value={inputValue}
        id={id}
        name={id}
        aria-label={children}
        aria-invalid={invalid}
        aria-multiline="true"
        className={getStyling()}
      />
      {invalid ? (
        <p
          className={classes.textAreaError}
          id="text-area-input-error"
          aria-errormessage="text-area-error"
          data-testid="text-area-error"
        >
          {error}
        </p>
      ) : null}
    </>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

TextArea.defaultProps = {
  id: '-1',
  placeholder: '',
  required: false,
  disabled: false,
  invalid: false,
  value: '',
  onChange: (id, value) => {
    console.log(id, value);
  },
  error: '',
};

export default TextArea;
