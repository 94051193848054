import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  UpdateCustomQuestion,
} from '../../../store/actions';

import { handleCustomQuestionsAndDPInstructionsUpdate } from '../../pages/moduleSelection/ModuleSelection.utility';

import styles from './QuestionForm.module.css';
import Tabs from '../../ui/nav/Tabs';
import Button from '../../ui/buttons/Button';
import RichTextEditor from '../../ui/input/RichTextEditor';

import Dropdown from '../../ui/Dropdown/Dropdown';

import DPInstructionTable from '../dpInstructionTable/DPInstructionTable';
import {
  showToaster,
    GetFormattedLocalQuestionName,
    updateCustomQuestion, updateObject
} from '../../../utility/utility';
import * as appMessages from '../../../utility/messages';

/**
 * @class QuestionForm
 * @param {Number} customQuestionId
 *
* @description This component is used to enable users to view and make modifications to a local question. Some changes a user can make are specific to the language version of a question ( such as the question text and answers ), in which case the user can switch between the wave's available languages and make the desired changes for each language individually. Others ( such as updating the question name, as well as its scripting and DP instructions ) apply across all language versions of a question. The component also allows users to save their changes by clicking the 'Save' button, which will set the question's status to 'Defined', as well as update the local question in the database.
 
* The **QuestionForm** component is used in the **ModuleSelection** component.

* @returns {JSX}
 */
const QuestionForm = ({ customQuestionId, clickHandler }) => {
  const { isLoading, customQuestions, error } = useSelector(
    (store) => store.customQuestions
  );
  const { wave } = useSelector((store) => store.wave);
  const dispatch = useDispatch();

  const customQuestionTypesArr = useSelector(
    (state) => state.customQuestions.customQuestionTypes
  );

  const [languages, setLanguages] = useState([]);
  const [customQuestion, setCustomQuestion] = useState({});
  const [currentLanguageId, setCurrentLanguageId] = useState(-1);
  const [currentTextField, setCurrentTextField] = useState({});
  const [dpInstructionFormsData, setDPInstructionFormsData] = useState([]);

  const [nonDPFormData, setNonDPFormData] = useState({
    name: '',
    variableCode: '',
    base: '',
    scriptingInstruction: '',
    questionType: '',
  });

  useEffect(() => {
    if (customQuestion.question) {
      setNonDPFormData({
        name: customQuestion.question.name ?? '',
        variableCode:
          GetFormattedLocalQuestionName(
            customQuestion?.customNamePrefix,
            customQuestion?.question?.name
          ) ?? '', // customQuestion.question.variableCode || '',
        base: customQuestion.question.base ?? '',
        scriptingInstruction: customQuestion.scriptingInstruction ?? '',
        questionType: customQuestion?.question?.questionType?.name ?? '',
      });
    }
  }, [customQuestion]);

  /**
   * @function
   * @memberof QuestionForm
   * @param {Event} e
   *
   * @description Handles the change of the Question Name, Type, Variable Code and Base, as well as its Scripting and DP instructions input fields. On change it calls the 'updateCustomQuestion' function, which updates the 'customQuestion' state variable to the new value that the user inputs.
   */

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNonDPFormData({
      ...nonDPFormData,
      [name]: value,
    });
    if (name === 'questionType') {
      const customQuestionTypeObj = customQuestionTypesArr.find(
        (cqt) => cqt.name === value
      );
      const newCustomQuestion = updateCustomQuestion(customQuestion, {
        questionTypeId: customQuestionTypeObj.questionTypeId,
        questionType: {
          questionTypeId: customQuestionTypeObj.questionTypeId,
          name: customQuestionTypeObj.name,
        },
      });
      setCustomQuestion(newCustomQuestion);
    } else if (name === 'name') {
      setNonDPFormData((existingitem) => ({
        ...existingitem,
        variableCode: GetFormattedLocalQuestionName(
          customQuestion.customNamePrefix,
          value
        ),
      }));

      const newCustomQuestion = updateCustomQuestion(customQuestion, {
        [name]: value,
        variableCode: GetFormattedLocalQuestionName(
          customQuestion.customNamePrefix,
          value
        ),
      });
      setCustomQuestion(newCustomQuestion);
    } else {
      const newCustomQuestion = updateCustomQuestion(customQuestion, {
        [name]: value,
      });
      setCustomQuestion(newCustomQuestion);
    }
  };

  /**
   * @function
   * @memberof QuestionForm
   * @param {Number} languageId Id of the language that is clicked on.
   * Calls 'setCurrentLanguageId' with the value of the 'languageId' argument, and stores 'languageId' in state.
   */
  const handleLanguage = (languageId) => {
    setCurrentLanguageId(languageId);
  };

  /**
   * @function
   * @memberof QuestionForm
   * @param {Number} questionTextFieldId Id of the question text field that is being modified.
   * @param {String} newValue Contents of the input field.
   * @param {String} name Name of the input field that is being modified
   *
   * @description Once the user changes the value of an input field that takes this function as its onchange method ( either 'questionText or 'questionAnswers' ), the function updates the 'customQuestion' state variable to reflect the changes to the modified input fields.
   */
  const handleChangeTextField = (questionTextFieldId, newValue, name) => {
    //update the textfields
    const textfields = customQuestion.question.questionTextFields;

    const newTextFields = textfields.map((t) => {
      return t.questionTextFieldId === questionTextFieldId
        ? updateObject(t, { [name]: newValue })
        : t;
    });

    //update the customQuestion
    const newCustomQuestion = updateObject(customQuestion, {
      question: updateObject(customQuestion.question, {
        questionTextFields: newTextFields,
      }),
    });

    setCustomQuestion(newCustomQuestion);
  };

  /**
   * @function
   * @memberof QuestionForm
   *
   * @description When the function is called it updates the 'customQuestion' state variable to set its status to 'Defined', and then it dispatches 'UpdateCustomQuestion', which updates the custom question in the database. On Save click 'getDPInstructionsDataToInsert' method gets those DPInstructions which are not hidden and whose values are not empty
   */

  const handleSubmit = () => {
    const dpDataToSave = getDPInstructionsDataToInsert(dpInstructionFormsData);
    let errorMessage = '';

    const { report, containsNonDPErrors, containsDPErrors } =
      validateFormDataAndDP(dpDataToSave, nonDPFormData);

    if (report.valid) {
      const newQ = updateCustomQuestion(customQuestion, {
        dpInstructions: [...dpDataToSave],
        status: 'Defined',
        variableCode: GetFormattedLocalQuestionName(
          customQuestion?.customNamePrefix,
          customQuestion?.question?.name
        ),
      });
      dispatch(UpdateCustomQuestion(newQ));
    } else {
      if (containsNonDPErrors && containsDPErrors) {
        errorMessage = 'Please fill Scripting and DP mandatory fields <br />';
      } else if (containsNonDPErrors && !containsDPErrors) {
        errorMessage = 'Please fill Scripting mandatory fields <br />';
      } else if (!containsNonDPErrors && containsDPErrors) {
        errorMessage = 'Please fill DP mandatory fields <br />';
      } 

      toasterContent.message = errorMessage + report.errorMessages;
      showToaster(toasterContent, true);
    }
    
  };


   

  const validateFormDataAndDP = (dpInstructionFormsData, nonDPFormData) => {
    
    report.errors = [];    

      const hasNonDPErrors = Object.values(nonDPFormData).some((value) => value === '' || value == null);
      const hasDPErrors = dpInstructionFormsData.some((form) => Object.values(form).some((value) => value === ''));

      if (hasNonDPErrors) {
          Object.keys(nonDPFormData).forEach((key) => {
              if (nonDPFormData[key] === '' || nonDPFormData[key] == null) {
                  report.errors.push(buildMessageText(null, key));
              }
          });
      }

      if (hasDPErrors) {
          dpInstructionFormsData.forEach((form, index) => {
              Object.keys(form).forEach((key) => {
                  if (form[key] === '') {
                      report.errors.push(buildMessageText(index, key));
                  }
              });
          });
      }

    if (report.errors.length > 0) {
      report.valid = false;
      report.errorMessages = appMessages.stackMessages(report.errors);
      }
      return { report, hasNonDPErrors, hasDPErrors };
  };

  const toasterContent = {
    title: 'Mandatory fields',
    message: '',
  };

  const buildMessageText = (index, fieldName) => {
    let errMessage = '';

    switch (fieldName) {
      case 'name':
        errMessage = `Question name is not added \n`;
        break;
      case 'variableCode':
        errMessage = `Question variable code is not added \n`;
        break;
      case 'questionType':
        errMessage = `Question type is not added \n`;
        break;
      case 'base':
        errMessage = `Question base is not added \n`;
        break;
      case 'scriptingInstruction':
        errMessage = `Question scripting instructions are not added \n`;
        break;
      case 'tableName':
        errMessage = `Question Name of DP Table ${index + 1} is not added \n`;
        break;
      case 'tableTitle':
        errMessage = `Short titile of DP Table ${index + 1} is not added \n`;
        break;
      case 'tableBase':
        errMessage = `Base/Filter of DP Table ${index + 1} is not added \n`;
        break;
      case 'dpInstruction':
        errMessage = `Instructions of DP Table ${index + 1} is not added \n`;
        break;
      case 'dpAdditionalInformation':
        errMessage = `Additional Information of DP Table ${
          index + 1
        } is not added \n`;
        break;
      default:
        break;
    }

    return errMessage;
  };

  const report = {
    valid: true,
    errorMessages: '',
    errors: [],
    failedProcesses: {},
  };

  /**
   * @function
   * @memberof QuestionForm
   *
   * @description This function gets DPInstructions which are not hidden(i.e. form is open) and whose form data is not null or empty
   */
  const getDPInstructionsDataToInsert = (dpInstructionFormsData) => {
    const requiredDPInstructionsDataToSave = dpInstructionFormsData.filter(
      (item) => !item.hidden
    );
    return requiredDPInstructionsDataToSave;
  };

  /**
   * @function
   * @memberof QuestionForm
   *
   * @description This function filters DPInstructions in which all properties are not null and empty to save the data.
   */
  

  /**
@method useEffect - 1st instance  
   * @memberof QuestionForm
   * @param {Array} customQuestions Array containing all local questions for this wave.
   * @param {Number} customQuestionId Id of the local question whose contents we are currently rendering.
   *
   * @description  When the current 'customQuestionId' changes, and is a key in 'customQuestions', it calls the handleCustomQuestionsUpdate function.
   * @see {@link '../../pages/moduleSelection/ModuleSelection.utility'}, lines 142-161.
   *
   * The effect is executed every time the values of either 'customQuestionId' or 'customQuestions' updates ( which is when the user clicks on a different local question ). This effect is executed only if the length of the 'customQuestions' array is greater than 1, and 'customQuestionId' is a key in the 'customQuestions' array.
   */
    useEffect(() => {   
        
    const loadDPInstructions = (dpInstructions) => {
        if (dpInstructions?.length > 0) {
            const dpInstructionsData = dpInstructions.map((item) => ({ ...item }));
            setDPInstructionFormsData([...dpInstructionsData]);
        } else {
            insertEmptyFormToDPInstructionFormData(customQuestionId, []);
        }
    };

    handleCustomQuestionsAndDPInstructionsUpdate(
        { customQuestions,customQuestionId},
       languages,
       wave,
       setLanguages,
       setCurrentLanguageId,
       setCustomQuestion,
       loadDPInstructions
    );
    }, [customQuestions, customQuestionId, languages, wave]);

  /**
@method useEffect - 2nd instance  
   * @memberof QuestionForm
   *
   * @description  If the value of 'currentLanguageId' is valid, it calls the 'setCurrentTextField' setter to the value to the appropriate item in the 'customQuestion.question.questionTextFields' array based on the question languageId.
   */
  useEffect(() => {
    if (currentLanguageId !== -1) {
      setCurrentTextField(
        customQuestion.question.questionTextFields.find((q) => {
          return q.languageId === currentLanguageId;
        })
      );
    }
  }, [currentLanguageId, customQuestion.question?.questionTextFields]);


    

  const addEmptyDPInstructionTableForm = () => {
    insertEmptyFormToDPInstructionFormData(
      customQuestionId,
      dpInstructionFormsData
    );
  };

  const insertEmptyFormToDPInstructionFormData = (
    customQuestionId,
    dpInstructionFormsData
  ) => {
    setDPInstructionFormsData([
      ...dpInstructionFormsData,
      {
        customQuestionId: customQuestionId,
        dpInstructionsId: 0,
        tableName: '',
        tableTitle: '',
        tableBase: '',
        dpInstruction: '',
        dpAdditionalInformation: '',
      },
    ]);
  };

  const handleToggleVisibility = (index, isHidden) => {
    const updatedInstructions = [...dpInstructionFormsData];
    updatedInstructions[index].hidden = isHidden;
    setDPInstructionFormsData(updatedInstructions);
  };

  const handleRestore = (index) => {
    const updatedInstructions = [...dpInstructionFormsData];
    delete updatedInstructions[index].hidden;
    setDPInstructionFormsData(updatedInstructions);
  };
  
  return (
    <div>
      {isLoading && <div>Loading...</div>}
      {error && <div className={styles.error}>{error}</div>}
      {customQuestion?.question && (
        <div className={styles.form}>
          <div className={styles.title}>
            {GetFormattedLocalQuestionName(
              customQuestion?.customNamePrefix,
              customQuestion?.question?.name
            )}
          </div>
          {/*<div className={styles.formControl}>
            <label>Question description</label>
            <p
              dangerouslySetInnerHTML={{
                __html: customQuestion.requestDescription,
              }}
            ></p>
            </div>*/}

          <div className={styles.formControl}>
            <label htmlFor="questionId">Question Name</label>
            <div>
              <label className={styles.localQuestionLabel}>
                {customQuestion.customNamePrefix}
                {'_'}
              </label>
              <input
                type="text"
                id="questionId"
                name="name"
                value={customQuestion.question.name}
                onChange={handleChange}
                className={styles.localQuestionInput}
              />
            </div>
          </div>
          <div className={`${styles.formControl} ${styles.formControlRow}`}>
            <div>
              <label
                className={styles.localQuestionLabel}
                htmlFor="variableCode"
              >
                Variable code
              </label>
              <span id="variableCode" name="variableCode">
                {nonDPFormData.variableCode ? nonDPFormData.variableCode : ''}
              </span>
            </div>
            <div>
              <Dropdown
                id="questionType"
                label="Question Type"
                name="questionType"
                value={nonDPFormData.questionType}
                options={customQuestionTypesArr?.map((type) => ({
                  label: type.name,
                  value: type.name,
                }))}
                onChange={handleChange}
                customStyle={styles.dropdown}
                placeholder="Select a type"
              />
            </div>
            <div>
              <label className={styles.localQuestionLabel} htmlFor="base">
                Base
              </label>
              <input
                type="text"
                id="base"
                name="base"
                value={nonDPFormData.base ? nonDPFormData.base : ''}
                onChange={handleChange}
                className={styles.localQuestionInput}
              />
            </div>
          </div>

          <div className={styles.tabContainer}>
            <Tabs
              labelsTabs={languages}
              idCurrent={currentLanguageId}
              handleOnClick={handleLanguage}
            ></Tabs>
          </div>
          <div className={styles.tabContent}>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <RichTextEditor
                label="Question text"
                name="questionText"
                value={currentTextField.questionText}
                id={currentTextField.questionTextFieldId}
                handleChange={handleChangeTextField}
              ></RichTextEditor>
            </div>
            <div className={styles.formControl}>
              <RichTextEditor
                label="Question answers"
                name="answerText"
                value={currentTextField.answerText}
                id={currentTextField.questionTextFieldId}
                handleChange={handleChangeTextField}
              ></RichTextEditor>
            </div>
          </div>

          <div className={styles.formControl}>
            <label htmlFor="scriptingInstruction">Scripting instructions</label>
            <textarea
              placeholder="Add the location of the question, randomization of answer options, dependencies on other previously asked questions , certain display etc."
              id="scriptingInstruction"
              name="scriptingInstruction"
              value={customQuestion.scriptingInstruction}
              onChange={handleChange}
            />
          </div>

          <div className={styles.formControl}>
            <label>DP Table Instructions</label>
          </div>

          {dpInstructionFormsData?.map((item, index) => {
            return (
              <DPInstructionTable
                id={item.dpInstructionsId}
                dpInstruction={item}
                index={index}
                dpInstructionFormsData={dpInstructionFormsData}
                setDPInstructionFormsData={setDPInstructionFormsData}
                onToggleVisibility={handleToggleVisibility}
                onRestore={handleRestore}
              />
            );
          })}

          <div className={styles.btnContainerCenter}>
            <Button
              type="primary"
              handleOnClick={addEmptyDPInstructionTableForm}
            >
              + Add another table
            </Button>
          </div>

          <div className={styles.btnContainer}>
            <Button type="primary" handleOnClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

QuestionForm.propTypes = {
  customQuestionId: PropTypes.number.isRequired,
};

QuestionForm.defaultProps = {
  customQuestionId: -1,
};

export default QuestionForm;
