import React from 'react';
import Button from '../../buttons/Button';
import styles from './ModalContent.module.css';
import { ReactComponent as CancelIcon } from "../../../../assets/svg/cancel.svg";

const ModalContent = ({ id, title, content,confirmBtnTitle, cancelBtnTitle, handleConfirm, handleCancel }) => {
  return (
    <div id = {id}>
      <div className={styles.header}>
      <div className={styles.Title} dangerouslySetInnerHTML={{ __html: title }} />
      <button
           className={styles.Close} onClick={() => handleCancel()}
          >
            <CancelIcon height={16} />
          </button>
      </div>
      
      
      <div className={styles.Message}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div className={styles.btnContainer}>
        <Button handleOnClick={() => handleCancel()} type="secondary">
          {cancelBtnTitle}
        </Button>
        <Button
          handleOnClick={() => {
            handleConfirm();
            handleCancel();
          }}
          type="primary">
          {confirmBtnTitle}
        </Button>
      </div>
    </div>
  );
};

export default ModalContent;
