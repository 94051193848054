import React from 'react';
import ModalContent from '../ModalContent/ModalContent';
import { useSelector } from 'react-redux';

const UnlockClientSelection = ({ id, handleConfirm, handleClose }) => {
  const messages = useSelector((state) => state.message.messages);

  return (<ModalContent
    id={id}
    title={messages?.unlockClientSelection?.title}
    content={messages?.unlockClientSelection?.text}
    confirmBtnTitle={'Unlock client selection'}
    cancelBtnTitle={'Cancel'}
    handleConfirm={handleConfirm}
    handleCancel={handleClose}
   />);
};

export default UnlockClientSelection;
