import React from 'react';
import PropTypes from 'prop-types';
import styles from './ComplexButton.module.css';
import deleteIcon from '../../../assets/svg/delete.svg';

/**
 * @component ComplexButton
 * @param {String} id - The unique identifier for the button.
 * @param {Function} handleOnClick - The click event handler function for the primary button.
 * @param {Function} handleOnClickIcon - The click event handler function for the secondary button (icon button).
 * @param {String} iconType - The type of icon for the secondary button.
 * @param {number} tabIndex - The tab index for keyboard navigation.
 * @param {String} children - The content to be displayed inside the primary button.
 * @param {boolean} disableSecondaryBtn - A flag indicating whether the secondary button should be disabled.
 * @param {String} focusedQ - The identifier for the focused question, used for conditional styling.
 *
 * @description A complex button component that can be of various types that is accessible, and accepts props as detailed above.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handlePrimaryClick = (id) => {
 *   console.log(`Primary button clicked with id: ${id}`);
 * };
 *
 * const handleIconClick = (id) => {
 *   console.log(`Icon button clicked with id: ${id}`);
 * };
 *
 * return (
 *   <ComplexButton
 *     id="complex-btn-1"
 *     handleOnClick={handlePrimaryClick}
 *     handleOnClickIcon={handleIconClick}
 *     iconType="delete"
 *     tabIndex={0}
 *     disableSecondaryBtn={false}
 *     focusedQ="focused-question-1"
 *   >
 *     Primary Button
 *   </ComplexButton>
 * );
 */
const ComplexButton = ({
  id,
  handleOnClick,
  handleOnClickIcon,
  iconType,
  tabIndex,
  children,
  disableSecondaryBtn,
  focusedQ,
}) => {
  let iconSrc = '';
  let iconAlt = '';
  if (iconType === 'delete') {
    iconSrc = deleteIcon;
    iconAlt = 'delete';
  }

  return (
    <div
      className={`${styles.btn_container} ${
        id === focusedQ ? styles.btn_container_clicked : ''
      }`}
    >
      <button
        aria-label="primarybutton"
        tabIndex={tabIndex}
        onClick={() => {
          handleOnClick(id);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleOnClick(id);
          }
        }}
      >
        {children}
      </button>
      {disableSecondaryBtn ? null : (
        <button
          aria-label="iconbutton"
          className={styles.icon_btn}
          tabIndex={tabIndex}
          onClick={() => {
            handleOnClickIcon(id);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              handleOnClickIcon(id);
            }
          }}
        >
          <img src={iconSrc} alt={iconAlt} />
        </button>
      )}
    </div>
  );
};

ComplexButton.propTypes = {
  id: PropTypes.node.isRequired,
  handleOnClick: PropTypes.func,
  handleOnClickIcon: PropTypes.func,
  iconType: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
  disableSecondaryBtn: PropTypes.bool,
};

ComplexButton.defaultProps = {
  id: -1,
  handleOnClick: (id) => {
    return id;
  },
  handleOnClickIcon: (id) => {
    return id;
  },
  iconType: 'delete',
  tabIndex: 0,
  children: '',
  disableSecondaryBtn: false,
};

export default ComplexButton;
