import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from './store/actions';
import CreateEditWave from './components/containers/createEditWave/CreateEditWave';
import ModuleSelection from './components/pages/moduleSelection/ModuleSelection';
import WaveSelection from './components/pages/waveSelection/WaveSelection';
import QuestionnairePreview from './components/containers/questionnairePreview/QuestionnairePreview';
import styles from './App.module.css';
import { PageLayout } from './components/PageLayout';

import Button from 'react-bootstrap/Button';

import { GetCountriesWaves } from './store/actions/wave';
import { fetchMessageList } from './store/actions/message';
import { getNotifications } from './store/actions/notification';

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, userName } = useSelector((state) => state.app.user);

  const login = () => {
    window.location.href = '/api/auth/login';
  };

  const logout = () => {
    window.location.href = '/api/auth/logout';
  };
  const initialLoad = () => {
    dispatch(fetchMessageList());
    dispatch(getUserData());
    dispatch(GetCountriesWaves());
    dispatch(getNotifications());
  };

  useEffect(() => {
      initialLoad();
  }, []);

  return (
    <>
      {isAuthenticated ? (
        <>
          <Router>
            <Routes>
              <Route
                path="/"
                element={<PageLayout userName={userName} logout={logout} />}
              >
                <Route index element={<Navigate to="waves" replace />} />
                <Route path="waves" element={<WaveSelection />} />
                <Route path="waves/:country" element={<WaveSelection />} />
                <Route
                  path="waves/:country/:waveId"
                  element={<ModuleSelection name={userName} />}
                />
                <Route
                  path="waves/:country/createWave"
                  element={
                    <CreateEditWave name={userName} autofillProcess={true} />
                  }
                />
                <Route
                  path="preview/:country/:waveId"
                  element={<QuestionnairePreview />}
                />
                <Route
                  path="preview/:country/:projectId/:modules"
                  element={<QuestionnairePreview />}
                />
                <Route
                  path="waves/:country/:waveId/editWave"
                  element={
                    <CreateEditWave name={userName} autofillProcess={false} />
                  }
                />
              </Route>
            </Routes>
          </Router>
        </>
      ) : (
        <>
          <div className={styles.unAuthenticatedBody}>
            <p>You are not signed in! Please sign in.</p>
            <Button
              variant="secondary"
              onClick={login}
              className={styles.signin}
            >
              Sign In
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default App;
