import * as actionTypes from './actionTypes';
import axios from '../../axiosInstance';

export const updateCrumbs = (payload) => {
  return {
    type: actionTypes.UPDATE_CRUMBS,
    crumbs: payload,
  };
};
export const updateUserSelectedProjects = (payload) => {
  return {
    type: actionTypes.UPDATE_USERPROJECTS_SELECTIONFILTER,
    userSelectedProjects: payload,
  };
};


export const getUserDataStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const getUserDataFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error.message,
  };
};

export const getUserDataSuccess = (userData) => {
  const user = {
    isAuthenticated: userData.isAuthenticated,
    user: userData.user,
    msalUser: userData.claims,
    userName: userData.claims?.name,
    role: userData.user?.role?.name,
    countries: userData.userCountries,
  };
  return {
    type: actionTypes.AUTH_SUCCESS,
    userData: user,
  };
};

export const getProjectIdsStart = () => {
  return {
    type: actionTypes.GET_PROJECT_IDS_START,
  };
};

export const getProjectIdsSuccess = (resObj) => {
  return {
    type: actionTypes.GET_PROJECT_IDS_SUCCESS,
    projects: resObj,
  };
};
export const getProjectIdsFail = (error) => {
  return {
    type: actionTypes.GET_PROJECT_IDS_FAIL,
    error: error.message,
  };
};

export const getUserData = () => {
  return (dispatch) => {
    dispatch(getUserDataStart());
    axios
      .get(`auth/user`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        dispatch(getUserDataSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getUserDataFail(error));
      });
  };
};


export const getProjectIds = () => {
  return (dispatch) => {
    dispatch(getProjectIdsStart());
    axios
      .get('Projects')
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        let resData = {};
          res.data.map((resDataObj) => {
              const resProjectId = resDataObj.projectId;

              const resProjectData = {
                  projectId: resProjectId,
                  name: resDataObj.name,
                  isActive: resDataObj.isActive,
                  projectCountries: resDataObj.projectCountries,
                  hasContent: resDataObj.hasContent
              };
             
              resData[resDataObj.projectId] = resProjectData;
              return resProjectData;
          
        });
        dispatch(getProjectIdsSuccess(resData));
      })
      .catch((error) => {
        dispatch(getProjectIdsFail(error));
      });
  };
};