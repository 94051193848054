import {
    checkMandatoryChoiceGroup,
  getMessageWithReplacedKeyValuePairs,
  getMessageWithReplacedKeyValue,
} from './utility';
import { getStore } from './injectStore';

const getMessages = () => {
  return getStore().message?.messages;
};
/**
 * @function
 * @memberof App
 * @param {Number} maxLoi The maximum length of interview value accepted for the wave.
 *
 * @description The function returns a string informing the user that their length of interview should be lower than the value of 'maxLoi', and asks them to review their module seelction.
 * @returns {String}
 */
export const LOImessage = (maxLoi) => {
  const messages = getMessages();
  return getMessageWithReplacedKeyValue(
    messages?.maxLOI?.text,
    'maxLoi',
    maxLoi
  );
};

/**
 * @function
 * @memberof App
 * @param {'hover' | 'toast'} messageType String stating how the message will be shown.
 * @param {Array} moduleNames Array of strings, with each string being the name of a module.
 *
 * @description The function performs the following actions and checks:
 *
 * 1. Creates a string containing an ordered list, where each list item is the name of a module in the 'moduleNames' array. The string is stored in the 'list' variable.
 * 2. Checks the value of 'messageType' and based on it updates the 'messageIntro' variable depending on the following conditions:
 *    * If 'messageType' equals 'hover':
 *      * If the string 'Brand & Product Image RMC' is included in any of the module names the value of the 'messageIntro' variable will be updated to inform the user that they need to select 'at least one out of four Brand & Product Image RMC modules', and 'list' will be emptied.
 *      * Otherwise the value of 'messageIntro' will be updated to inform the user they neeed to select at least one of the modules.
 *    * If 'messageType' equals 'toast' the value of 'messageIntro' will be updated to inform the user that they need to select at least one of the modules.
 *
 * 3. Returns a string containing the values of 'messageIntro' and 'list'.
 * @returns {String}
 */
export const setModuleMandatoryMessage = (messageType, moduleNames) => {
  const messages = getMessages();
  const isBPIModules = moduleNames.find((string) =>
    string.includes('Brand & Product Image RMC')
  );
  //list of modules for the toast notification
  let lista = moduleNames.join('</li><li>');
  let list = '<ul><li>' + lista + '</li></ul>';

  let messageIntro = '';
  if (messageType === 'hover') {
    //Specific message for Brand & Product Image RMC
    if (isBPIModules) {
      messageIntro = messages?.brandProductImageRMC?.text;
      list = '';
    } else {
      //leaving an option for another mandatory 1 of many group message
      messageIntro = messages?.mandatoryChoiceHover?.text;
    }
  } else if (messageType === 'toast') {
    messageIntro = messages?.mandatoryChoiceToast?.text;
  }
  return `${messageIntro} ${list}`;
};

/**
 * @function
 * @memberof App
 * @param {Array} messagesObj Array containing names of modules
 *
 * @description Iterates over 'messagesObj', appending each name to a string with its order number (starting from 1) and HTML line breaks.
 *
 * @returns {String} Formatted HTML string of module names with their order numbers, and <br /> elements after each module.
 */
export const stackMessages = (messagesObj) => {
  let messages = '';
  Object.values(messagesObj).forEach((error, index) => {
    messages += `${index + 1}. ${error} <br />`;
  });
  return messages;
};

/**
 * @function
 * @memberof App
 * @param {'missingProcess' | 'missingValue' | 'missingName'} type - The error type.
 *
 * @description The function creates and returns an object 'msgObj', with 'title' and 'message' properties. The values of these properties are determined by the 'type' parameter:
 *  * If 'type' equals 'missingProcess' - The title is set to 'Creating a wave:', and the message informs the user to add at least one process including dates and status before saving a new project wave.
 *  * If 'type' equals 'missingValue' - The title is set to 'Wave Process Details:', and the message indicates the need to add start and end dates and select a status for at least one process before saving a new wave.
 *  * If 'type' equals 'missingName' - The title is set to 'Creating a wave:', and the message advises including a project wave name before saving a new project wave.
 *
 * @returns {Object} Object containing 'title' and 'message' properties with specific instructions based on the error type.
 */
export const setCreateWaveErrorMessage = (type) => {
  const messages = getMessages();
  let msgObj = {
    title: '',
    message: '',
  };
  if (type === 'missingProcess') {
    msgObj.title = messages?.createWaveErrorMessageOnMissingProcess?.title;
    msgObj.message = messages?.createWaveErrorMessageOnMissingProcess?.text;
  } else if (type === 'missingValue') {
    msgObj.title = messages?.createWaveErrorMessageOnMissingValue?.title;
    msgObj.message = messages?.createWaveErrorMessageOnMissingValue?.text;
  } else if (type === 'missingName') {
    msgObj.title = messages?.createWaveErrorMessageOnMissingName?.title;
    msgObj.message = messages?.createWaveErrorMessageOnMissingName?.text;
  } else if (type === 'incorrectDate') {
    msgObj.title = messages?.createWaveErrorMessageOnIncorrectDate?.title;
    msgObj.message = messages?.createWaveErrorMessageOnIncorrectDate?.text;
  }
  else{ 
    msgObj.title = "Something went wrong!"
    msgObj.title = `An unhandled error named ${type} occured. Please contact an administrator.`
  }

  return msgObj;
};

/**
 * @function
 * @memberof App
 * @param {Object} groupData - Data object containing information about the module group.
 * @param {Object} mandatoryChoiceGroup - Object containing the properties 'rule' and 'valid', corresponding to the group rule and validity of the group.
 * @param {Boolean} savedModuleSelection - Indicates if a module selection has been saved.
 * @param {Array} moduleNames - Array of module names for the mandatory choice.
 * 
 * @description This function determines and returns a message based on various conditions:
 *  * If the module needs to be selected at least once in the current year and it is the last wave of the year, it returns a message stating the module has been automatically selected for the current wave.
 *  * If the module only needs to be selected once per year, it returns a message indicating it will become mandatory in the last wave of the year if not selected earlier.
 *  * If a group of modules has a mandatory selection rule that applies once per year, and it's the last wave of the year, a message is returned indicating the group's module needs to be selected in this wave. If it's not the last wave, the message indicates that the group's validation rule will apply in the last wave.
 *  * In case of a mandatory choice rule, it generates a message listing the modules from which at least one should be selected, formatted in HTML.
 * If none of these conditions are met, the function returns an empty string.
 *
 * 
 * @returns {String} A message string explaining the mandatory selection rule or an empty string if no specific message is required.

 */
export const handleMandatoryMessage = (
  groupData,
  mandatoryChoiceGroup,
  savedModuleSelection,
  moduleNames
) => {
  const messages = getMessages();
  if (groupData.mandatoryOncePerYear && groupData.mandatoryThisWave) {
    return messages?.moduleMandatoryOncePerYearLastWave?.text;
  } else if (groupData.mandatoryOncePerYear) {
    return messages?.moduleMandatoryOncePerYear?.text;
  } else if (
    groupData.mandatoryGroupRuleOncePerYear &&
    groupData.mandatoryGroupThisWave
  ) {
    return messages?.groupMandatoryOncePerYearLastWave?.text;
  } else if (groupData.mandatoryGroupRuleOncePerYear) {
    return messages?.groupMandatoryOncePerYear?.text;
  } else if (checkMandatoryChoiceGroup(mandatoryChoiceGroup)) {
    let modules = '<ul>';
    moduleNames.forEach((mod) => {
      modules += `<li>${mod}</li>`;
    });
    modules += '</ul>';
    if (!mandatoryChoiceGroup.valid && !savedModuleSelection) {
      return getMessageWithReplacedKeyValue(
        messages?.mandatoryChoiceGroupValidNotSavedModuleSelection?.text,
        'modules',
        modules
      );
    } else if (!mandatoryChoiceGroup.valid && savedModuleSelection) {
      return getMessageWithReplacedKeyValue(
        messages?.mandatoryChoiceGroupNotValidSavedModuleSelection?.text,
        'modules',
        modules
      );
    }
  }
  return '';
};

/**
 * @function
 * @memberof App
 * @param {'select' | 'deselect' | 'undo'} action
 * @param {Array} modules Array containing the module names which were automatically selected/deselecte/undone.
 * @param {String} trigger The name of the module on which this function was called.
 *
 * @description The function creates and returns an object 'messageObj', whose title property equals 'Automatic module selection'. Based on the type of 'action' the value of the 'message' property on the object will vary:
 *  * If 'action' equals 'select' - The message string contains the name of the module that was selected and a list of the modules that were automatically selected.
 *  * If 'action' equals 'undo' - This happens when the user deselects the same module that they selected, undoing their last selection. The message string contains the name of the module that was deselected and a list of the modules that were automatically deselected.
 *  * If 'action' equals 'deselect' - The message string contains the name of the module that was deselected and a list of the modules that were automatically deselected.
 *
 * @returns {Object} Object containing the properties 'title' ( whose value equals 'Automatic module selection' ), and message' ( whose value varies depending on the action as detailed above ).
 */
//using the message for cascadingSelection, if needed can be reworked to use separate messages coming from DB
export const dependencyChoiceMessages = (action, modules, trigger) => {
  const messages = getMessages();
  let messageList = modules;
  let msgObj = {
    title: 'Automatic module selection',
    message: '',
  };
  const replacementKeyValues = [
    { key: 'trigger', value: trigger },
    { key: 'messageList', value: messageList },
  ];
  if (action === 'deselect') {
    msgObj.message = getMessageWithReplacedKeyValuePairs(
      messages?.cascadingSelectionMessageOnDeselect?.text,
      replacementKeyValues
    );
  }
  return msgObj;
};

export const cascadingSelectionMessages = (action, modules, trigger) => {
  const messages = getMessages();
  let messageList = stackMessages(modules);
  let msgObj = {
    title: 'Automatic module selection',
    message: '',
  };
  const replacementKeyValues = [
    { key: 'trigger', value: trigger },
    { key: 'messageList', value: messageList },
  ];

  if (action === 'select') {
    msgObj.message = getMessageWithReplacedKeyValuePairs(
      messages?.cascadingSelectionMessageOnSelect?.text,
      replacementKeyValues
    );
  } else if (action === 'deselect') {
    msgObj.message = getMessageWithReplacedKeyValuePairs(
      messages?.cascadingSelectionMessageOnDeselect?.text,
      replacementKeyValues
    );
  } else if (action === 'undo') {
    msgObj.message = getMessageWithReplacedKeyValuePairs(
      messages?.cascadingSelectionMessageOnUndo?.text,
      replacementKeyValues
    );
  }
  return msgObj;
};
