import { updateObject } from '../../utility/utility';
import * as actionTypes from '../actions/actionTypes';
import { cloneDeep as _cloneDeep } from 'lodash';

const initialState = {
  customQuestions: {},
  isLoading: true,
  error: null,
};

const CustomQuestionsStart = (state) => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

const CustomQuestionsFail = (state, action) => {
  return {
    ...state,
    customQuestions: {},
    isLoading: false,
    error: action.error,
  };
};

const GetCustomQuestionsSuccess = (state, action) => {
  return {
    ...state,
    customQuestions: action.customQuestions,
    isLoading: false,
    error: null,
  };
};
const GetCustomQuestionTypesSuccess = (state, action) => {
  return {
    ...state,
    customQuestionTypes: action.customQuestionTypes,
    isLoading: false,
    error: null,
  };
};
const GetCustomQuestionTypesFail = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

const DeleteCustomQuestionSuccess = (state, action) => {
  const newQuestions = _cloneDeep(state.customQuestions);
  delete newQuestions[action.id];

  return {
    ...state,
    customQuestions: newQuestions,
    isLoading: false,
    error: null,
  };
};

const CreateCustomQuestionSuccess = (state, action) => {
  const updatedQuestion = updateObject(
    state.customQuestions[action.id],
    action.customQuestion
  );
  return updateObject(state, {
    customQuestions: { ...state.customQuestions, [action.id]: updatedQuestion },
    isLoading: false,
  });
};

const UpdateCustomQuestionSuccess = (state, action) => {
  const newCustomQuestion = updateObject(
    state.customQuestions[action.customQuestion.customQuestionId],
    action.customQuestion
  );

  const newCustomQuestions = updateObject(state.customQuestions, {
    [action.customQuestion.customQuestionId]: newCustomQuestion,
  });

  return {
    ...state,
    customQuestions: newCustomQuestions,
    isLoading: false,
    error: null,
  };
};

const updateCustomQuestion = (state, action) => {
  const updatedQuestion = updateObject(state.customQuestions[action.id], {
    ...state.customQuestions[action.id],
    requestDescription: action.description,
  });
  return updateObject(state, {
    customQuestions: { ...state.customQuestions, [action.id]: updatedQuestion },
  });
};

const QuestionFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOM_QUESTIONS_START:
      return CustomQuestionsStart(state);
    case actionTypes.GET_CUSTOM_QUESTIONS_SUCCESS:
      return GetCustomQuestionsSuccess(state, action);
    case actionTypes.GET_CUSTOM_QUESTION_TYPES_SUCCESS:
      return GetCustomQuestionTypesSuccess(state, action);
    case actionTypes.GET_CUSTOM_QUESTION_TYPES_FAIL:
      return GetCustomQuestionTypesFail(state, action);
    case actionTypes.GET_CUSTOM_QUESTIONS_FAIL:
      return CustomQuestionsFail(state, action);
    case actionTypes.CREATE_CUSTOM_QUESTION_START:
      return CustomQuestionsStart(state);
    case actionTypes.CREATE_CUSTOM_QUESTION_SUCCESS:
      return CreateCustomQuestionSuccess(state, action);
    case actionTypes.UPDATE_CUSTOM_QUESTION_FAIL:
      return CustomQuestionsFail(state, action);
    case actionTypes.UPDATE_CUSTOM_QUESTION_START:
      return CustomQuestionsStart(state);
    case actionTypes.UPDATE_CUSTOM_QUESTION_SUCCESS:
      return UpdateCustomQuestionSuccess(state, action);
    case actionTypes.CREATE_CUSTOM_QUESTION_FAIL:
      return CustomQuestionsFail(state, action);
    case actionTypes.UPDATE_CUSTOM_QUESTION:
      return updateCustomQuestion(state, action);
    case actionTypes.DELETE_CUSTOM_QUESTION_START:
      return CustomQuestionsStart(state);
    case actionTypes.DELETE_CUSTOM_QUESTION_SUCCESS:
      return DeleteCustomQuestionSuccess(state, action);
    case actionTypes.DELETE_CUSTOM_QUESTION_FAIL:
      return CustomQuestionsFail(state, action);
    default:
      return state;
  }
};

export default QuestionFormReducer;
