export const msalConfig = {
  auth: {
    clientId: '2e178788-3c85-4e79-8401-dd4b53c7f0c4',
    authority:
      'https://login.microsoftonline.com/60f3f635-a520-4eb7-a859-dbc5572fc839',
    redirectUri: window.location.protocol + '//' + window.location.host + '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['api://d8327218-938a-44a7-bfd3-ace6493a321d/API.Access'],
};

export const apiConfig = {
  apiEndpoint: 'api',
};
