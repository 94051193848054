import React from 'react';
import styles from './Logo.module.css';
import { Link } from 'react-router-dom';

/**
 * @component Logo
 * @param {String} customStyle - Custom styles to apply to the logo.
 *
 * @description Renders a logo component that links to the home page.
 *
 * @returns {JSX.Element}
 */
const Logo = (props) => {
  return (
    <Link to="/" className={styles.Link}>
      <div className={`${styles.Logo} ${props.customStyle}`}>
        <span className={styles.Orange}>GfK</span>Author
      </div>
    </Link>
  );
};

export default Logo;
