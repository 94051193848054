

import React from 'react';
import styles from '../dpInstructionTable/DPInstructionTable.module.css';
import TextArea from '../../ui/TextArea/TextArea';
import TextInput from '../../ui/TextInput/TextInput';

import Button from '../../ui/buttons/Button';

const DPInstructionTable = ({ id, dpInstructionFormsData, setDPInstructionFormsData, index, onToggleVisibility, onRestore }) => {    
    const handleDataChange = (name,value) => {
    const updatedForm = [...dpInstructionFormsData]; 
    updatedForm[index][name] = value;
    setDPInstructionFormsData(updatedForm);
    };    

      
    return (
        <div>
        {dpInstructionFormsData[index]?.hidden ? 
        
        (<div><Button type={'selectionMini'} handleOnClick={() => onRestore(index)}>Restore</Button>
                    </div>)
                    
                    :
          (
        <div key={id}>
            
            <div className={`${styles.Wrapper}`}>
                <div className={styles.SelectionMenu}>
                    <div>                   
                   
                        <TextInput children="Question Name" id="tableName" value={dpInstructionFormsData[index]?.tableName} onChange={handleDataChange}>
                        </TextInput>                        
                    </div>
                    <div >
                        <TextInput  children="Short title" id="tableTitle" value={dpInstructionFormsData[index]?.tableTitle} onChange={handleDataChange}>
                        </TextInput>
                    </div>
                    <div>
                        <TextInput  children="Base / Filter" id="tableBase" value={dpInstructionFormsData[index]?.tableBase} onChange={handleDataChange}>
                        </TextInput>
                    </div>
                    
                    {dpInstructionFormsData.filter(item => !item.hidden).length > 1 ?
                       <Button type={'button-specificclose'} handleOnClick={() => onToggleVisibility(index, true)}>

                       </Button>
                       :
                    ""}
                </div>               
               
                <div className={styles.SelectionMenu}>
                    <div className={styles.InputItem}>                       
                        <TextArea  children="Instructions" id="dpInstruction" value={dpInstructionFormsData[index]?.dpInstruction} placeholder="Add anything that falls outside the tab standards such as different target groups, banner, mean/average-calculations, combinations with other questions, NETs, merged codes, various number of tables, SKU/Umbrella Brand aggregation etc."
                         onChange={handleDataChange}>

                        </TextArea>                        
                    </div>
                </div>
                <div className={styles.SelectionMenu}>
                    <div className={styles.InputItem}>
                        <TextArea children="Additional Information" id="dpAdditionalInformation" value={dpInstructionFormsData[index]?.dpAdditionalInformation}
                            onChange={handleDataChange}>
                        </TextArea>
                </div>
                
                </div>
            </div>
        </div>
          )
        }
        </div>
    );

}
  
export default DPInstructionTable;



