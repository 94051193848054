import React  from 'react';
import ModalContent from '../ModalContent/ModalContent';
import { useSelector } from 'react-redux';

const RequestClientSelectionUnlock = ({ id, handleConfirm, handleClose }) => {
  const messages = useSelector((state) => state.message.messages);
  
  return (<ModalContent
   id={id}
   title={messages?.requestClientSelectionUnlock?.title}
   content={messages?.requestClientSelectionUnlock?.text}
   confirmBtnTitle={'Request unlock'}
   cancelBtnTitle={'Cancel'}
   handleConfirm={handleConfirm}
   handleCancel={handleClose}
  />);
};

export default RequestClientSelectionUnlock;
