import React, { useState } from 'react';
import Button from '../buttons/Button';
import styles from './WaveDelete.module.css';

/**
 * @component WaveDeletePopup
 * @param {String} waveName - The name of the wave to be deleted.
 * @param {Function} handleClose - A callback function to close the popup.
 *
 * @description Component used for confirming the deletion of a wave.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleClose = (action) => {
 *   console.log(`Popup closed with action: ${action}`);
 * };
 *
 * const waveName = 'Wave 1';
 *
 * return (
 *   <WaveDeletePopup
 *     waveName={waveName}
 *     handleClose={handleClose}
 *   />
 * );
 */
function WaveDeletePopup({ waveName, handleClose }) {
  const [input, setInput] = useState('');
  const [showError, setShowError] = useState(false);

  /**
   * @function handleClick
   * @memberof WaveDeletePopup
   *
   * @description Handles button click to confirm wave deletion. Calls the handleClose callback with 'delete' action if the input matches the waveName, otherwise shows an error.
   */
  const handleClick = () => {
    if (waveName === input) {
      handleClose('delete');
    } else {
      setShowError(true);
    }
  };

  /**
   * @function handleInputUpdate
   * @memberof WaveDeletePopup

   * @param {Object} e - The input event object.
   * 
   * @description Handles input updates and clears the error if the input matches the waveName when the user is prompted to confirm the name of the wave being deleted.
   */
  const handleInputUpdate = (e) => {
    if (showError && e.target.value === waveName) {
      setShowError(false);
    }
    setInput(e.target.value);
  };
  return (
    <>
      <div className={styles.Close} onClick={() => handleClose('cancel')}>
        X
      </div>
      <div className={styles.Title}>
        <h4>Delete wave</h4>
      </div>
      <div className={styles.Message}>
        <div>
          You are about to delete wave {waveName}.{<br />}Please confirm by
          typing the wave name in the input box below.
        </div>
        <div className={styles.Input}>
          <input
            data-testid="deleteWaveInput"
            value={input}
            onInput={(e) => handleInputUpdate(e)}
          />
        </div>
      </div>
      {showError && (
        <div className={styles.DeleteError}>
          The provided name does not match the wave you are trying to delete.
        </div>
      )}
      <div className={styles.Btn}>
        <Button handleOnClick={handleClick} type="primary">
          DELETE
        </Button>
      </div>
    </>
  );
}

export default WaveDeletePopup;
