import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AccordionDisplay.module.css';

import iconUp from '../../../assets/svg/accordion_up.svg';
import iconDown from '../../../assets/svg/accordion_down.svg';
import Button from '../buttons/Button';
import StatusGrid from '../grid/StatusGrid';
import { getFormattedDate } from '../../../utility/utility';

/**
 * @component AccordionDisplayItem
 * @param {String} waveId - The ID of the wave.
 * @param {String} name - The name of the wave.
 * @param {Object} project - The project associated with the wave.
 * @param {Object} waveStatus - The status of the wave.
 * @param {Boolean} expanded - Indicates if the wave details are expanded.
 * @param {Number} currentWaveId - The ID of the current wave.
 * @param {Function} onClickModuleSelection - Function to handle click on module selection.
 * @param {Function} onClickEditWave - Function to handle click on edit wave.
 * @param {Function} statusToStatusType - Function that provides the item's status in a specific format ( which is then used for styling ).
 * @param {boolean} editWavesPrivilege - Indicates if the user has edit waves privilege.
 * @param {Function} onClickModulePreview - Function to handle click on module preview.
 *
 * @description A component that displays an individual item within the accordion.
 *
 * @returns {JSX}
 *
 * @example
 * const waveProcesses = [
 *   { process: { name: 'Fieldwork' }, startDate: '2024-01-01', endDate: '2024-01-31' },
 *   { process: { name: 'Module selection' }, startDate: '2024-02-01', endDate: '2024-02-28' }
 * ];
 * const waveStatus = { status: 'In progress' };
 * const project = { name: 'Project 1' };
 * const currentWaveId = 1;
 * const onClickModuleSelection = (country, waveId) => {
 *   console.log(`Module selection clicked for wave ${waveId} in ${country}`);
 * };
 * const onClickEditWave = (country, waveId) => {
 *   console.log(`Edit wave clicked for wave ${waveId} in ${country}`);
 * };
 * const onClickModulePreview = (country, waveId) => {
 *   console.log(`Module preview clicked for wave ${waveId} in ${country}`);
 * };
 * const statusToStatusType = (status) => {
 *   const statusTypes = {
 *     'Pending': 'pendingStatus',
 *     'In progress': 'inProgressStatus',
 *     'Finished': 'finishedStatus'
 *   };
 *   return statusTypes[status] || 'defaultStatus';
 * };
 * const editWavesPrivilege = true;
 * const testLink = 'http://example.com/test';
 * const reportingLink = 'http://example.com/report';
 *
 * return (
 *   <AccordionDisplayItem
 *     waveId={1}
 *     name="Wave 1"
 *     project={project}
 *     waveStatus={waveStatus}
 *     waveProcesses={waveProcesses}
 *     testLink={testLink}
 *     reportingLink={reportingLink}
 *     expanded={false}
 *     currentWaveId={currentWaveId}
 *     onClickModuleSelection={onClickModuleSelection}
 *     onClickEditWave={onClickEditWave}
 *     statusToStatusType={statusToStatusType}
 *     editWavesPrivilege={editWavesPrivilege}
 *     onClickModulePreview={onClickModulePreview}
 *   />
 * );
 */
const AccordionDisplayItem = ({
  waveId,
  name,
  project,
  waveStatus,
  waveProcesses,
  testLink,
  reportingLink,
  expanded,
  currentWaveId,
  onClickModuleSelection,
  onClickEditWave,
  statusToStatusType,
  editWavesPrivilege,
  onClickModulePreview,
}) => {
  const [showDetail, setShowDetail] = useState(expanded);
  const [ref, setRef] = useState(null);
  const refCurrentWave = useRef(null);

  /**
@method useEffect - 1st instance  
   * @memberof AccordionDisplayItem
   *
   * @description When 'waveId' equals 'currentWaveId' the effect expands the details for the item and sets 'ref' to the ref of the 'currrent wave. When 'waveId' does not equal 'currentWaveId' 'ref' is set to null.
   */
  useEffect(() => {
    // if the waveId equals the currentWaveId expand the details and set ref to the ref of the currentWave
    if (waveId === currentWaveId) {
      setShowDetail(true);
      setRef(refCurrentWave);
    } else {
      setRef(null);
    }
  }, [currentWaveId,waveId]);

  /**
@method useEffect - 2nd instance  
   * @memberof AccordionDisplayItem
   * @description SCrolls the component into view when it is expanded.

   */
  useEffect(() => {
    refCurrentWave.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref]);

  const waveFieldworkObj = useRef(
    waveProcesses.find(
      (waveProcess) => waveProcess.process?.name === 'Fieldwork'
    )
  );

  return (
    <div
      data-testid={waveId}
      aria-label={name}
      aria-expanded={showDetail}
      ref={ref}
    >
      {/* collapsed */}
      {!showDetail && (
        <div className={styles.item}>
          <header>
            {/* the toggle button */}
            <div className={styles.displayLeft}>
              <button
                className={styles.itemBtn}
                aria-label={name}
                onClick={() => {
                  setShowDetail(!showDetail);
                }}
              >
                {showDetail ? (
                  <img src={iconUp} alt="-" />
                ) : (
                  <img src={iconDown} alt="+" />
                )}
                {name}
                {waveFieldworkObj.current && waveFieldworkObj.current.startDate
                  ? ' | ' + getFormattedDate(waveFieldworkObj.current.startDate)
                  : null}
              </button>
              {/* the module selection button */}
              {waveProcesses.find(
                (waveProcess) => waveProcess.process.name === 'Module selection'
              ) !== undefined ? (
                <Button
                  type="selection"
                  id={waveId}
                  handleOnClick={onClickModuleSelection}
                >
                  Module selection
                </Button>
              ) : null}
            </div>
            {}
            <div className={styles.displayRight}>
              Status: {waveStatus.status}
            </div>
          </header>
        </div>
      )}
      {/* expanded */}
      {showDetail && (
        <div className={`${styles.detail} ${styles.detailGrow}`}>
          <header>
            {/* the toggle button */}
            <button
              className={styles.detailBtn}
              aria-label={name}
              onClick={() => {
                setShowDetail(!showDetail);
              }}
            >
              {showDetail ? (
                <img src={iconUp} alt="-" />
              ) : (
                <img src={iconDown} alt="+" />
              )}
              {name}
              {waveFieldworkObj.current?.startDate
                ? ' | ' + getFormattedDate(waveFieldworkObj.current.startDate)
                : null}
            </button>
            <div className={styles.displayRight}>
              Status: {waveStatus.status}
            </div>
          </header>
          {/* the details */}
          <div className={styles.detailContent}>
            <StatusGrid
              statusItems={waveProcesses}
              testlink={testLink}
              reportinglink={reportingLink}
              statusToStatusType={statusToStatusType}
              projectType={project.name}
            />
          </div>
          <footer>
            <div className={styles.displayRight}>
              {/* the module selection button */}
              {waveProcesses.find(
                (waveProcess) => waveProcess.process.name === 'Module selection'
              ) !== undefined ? (
                <div className={styles.btnContainer}>
                  <Button
                    type="primary"
                    id={waveId}
                    handleOnClick={onClickModuleSelection}
                  >
                    Module selection
                  </Button>
                  <Button
                    type="primary"
                    id={waveId}
                    handleOnClick={onClickModulePreview}
                  >
                    Preview
                  </Button>
                </div>
              ) : null}
              {editWavesPrivilege ? (
                <Button
                  type="secondary"
                  id={waveId}
                  handleOnClick={onClickEditWave}
                >
                  Edit wave details
                </Button>
              ) : null}
            </div>
          </footer>
        </div>
      )}
    </div>
  );
};

AccordionDisplayItem.propTypes = {
  waveId: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  waveStatus: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClickModuleSelection: PropTypes.func.isRequired,
  onClickModulePreview: PropTypes.func.isRequired,
};

AccordionDisplayItem.defaultProps = {
  waveId: -1,
  name: 'default',
  project: { name: 'default' },
  waveStatus: { status: 'default' },
  expanded: false,
  onClickModuleSelection: (waveId) => {
    return waveId;
  },
  onClickModulePreview: (waveId) => {
    return waveId;
  },
};

export default AccordionDisplayItem;
