import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.css';


/**
 * @component Checkbox
 * @param {String} props.id - The unique identifier for the checkbox.
 * @param {String} props.label - The label to display next to the checkbox.
 * @param {Function} props.changed - The callback function to execute when the checkbox state changes.
 * @param {Function} props.clicked - The callback function to execute when the checkbox is clicked.
 * @param {Boolean} props.mandatory - A flag indicating whether the checkbox is mandatory (cannot be unchecked).
 * @param {Boolean} props.disabled - A flag indicating whether the checkbox is disabled.
 * @param {Boolean} props.checked - A flag indicating whether the checkbox is initially checked.
 * @param {Boolean} props.active - A flag indicating whether the checkbox is active.
 * @param {Boolean} props.radio - A flag indicating whether the checkbox behaves as a radio button.
 *
 * @description Renders a checkbox or radio button component with customizable behavior and styling.
 *
 * @returns {JSX.Element}
 *
 * @example
 * const handleCheckboxChange = (checked, id) => {
 *   console.log(`Checkbox with id ${id} is now ${checked ? 'checked' : 'unchecked'}`);
 * };
 *
 * const handleCheckboxClick = (id) => {
 *   console.log(`Checkbox with id ${id} was clicked`);
 * };
 *
 * return (
 *   <Checkbox
 *     id="checkbox-1"
 *     label="Accept Terms and Conditions"
 *     changed={handleCheckboxChange}
 *     clicked={handleCheckboxClick}
 *     mandatory={false}
 *     disabled={false}
 *     checked={true}
 *     active={true}
 *     radio={false}
 *   />
 * );
 */

function Checkbox(props) {
  const [checked, setChecked] = useState(
    props.mandatory || props.checked || false
  );

  /**
   * @function
   * @description Generates the CSS classes for the checkbox based on its properties and state.
   * @returns {string} A string containing CSS classes for styling the checkbox.
   */
  const getStyling = () => {
    let styleList = `${styles.checkboxDiv} ${
      props.active ? styles.Active : ''
    }`;
    if (props.additionalStyling?.length > 0) {
      props.additionalStyling.forEach((x) => {
        styleList += ` ${styles[x]}`;
      });
    }
    return styleList;
  };

  /**
   * @function checkClick
   * @description Handles the click event on the checkbox, triggering appropriate action to update the checked status of the checkbox ( provided that the checkbox isn't mandatory and disabled ).
   * @private
   */
  let checkClick = () => {
    if (props.clicked) {
      props.clicked(props.id);
    }
    if (!props.mandatory && !props.disabled) {
      props.changed(!checked, props.id);
      setChecked(!checked);
    }
  };

  /**
   * @method useEffect
   * @memberof Checkbox
   * @param {Boolean} props.checked
   *
   * @description Updates the 'checked' state variable to the value of 'props.checked' when 'props.checked' is updated.
   */
  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked, props.forceRerender]);

  return (
    <div data-testid="checkbox" onClick={checkClick} className={getStyling()}>
      <input
        type="checkbox"
        data-testid={props?.testId || 'checkbox-input'}
        id={`checkbox-${props.id}`}
        checked={checked || false}
        disabled={props.mandatory || props.disabled}
        onChange={(e) => {}}
        className={styles.checkboxInput}
        aria-checked={checked || false}
      />
      <div
        className={`${props.radio ? styles.Radiobutton : styles.Checkbox} ${
          props.mandatory || props.disabled ? styles.Disabled : ''
        } ${checked ? styles.Checked : ''}`}
      ></div>
      <div role="presentation" className={styles.Label}>
        {props.label}
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  changed: PropTypes.func.isRequired,
  clicked: PropTypes.func,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  active: PropTypes.bool,
  radio: PropTypes.bool,
};

Checkbox.defaultProps = {
  id: -1,
  label: '',
  changed: (checked, id) => {
    console.log(checked, id);
  },
  clicked: (id) => {},
  mandatory: false,
  disabled: false,
  checked: false,
  active: false,
  radio: false,
};

export default Checkbox;
