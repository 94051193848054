import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import AccordionMenuItem from './AccordionMenuItem';

/**
 * @component AccordionMenu
 * @param {Array} items - The list of items to display in the accordion menu.
 * @param {Function} onClickSubitem - Function to handle click on a subitem.
 * @param {Function} onClickItem - Function to handle click on an item.
 * @param {Number} idCurrentSubitem - The ID of the current subitem.
 * @param {Number} currentCountryId - The ID of the current country.
 * @param {Number} scrollTo - The ID of the subitem to scroll to.
 *
 * @description A component that displays an accordion menu with items and subitems.
 *
 * @returns {JSX}
 *
 * @example
 * const items = [
 *   {
 *     id: 1,
 *     name: 'Country 1',
 *     expanded: true,
 *     subitems: [
 *       { id: 101, name: 'Subitem 1-1' },
 *       { id: 102, name: 'Subitem 1-2' }
 *     ]
 *   },
 *   {
 *     id: 2,
 *     name: 'Country 2',
 *     expanded: false,
 *     subitems: [
 *       { id: 201, name: 'Subitem 2-1' },
 *       { id: 202, name: 'Subitem 2-2' }
 *     ]
 *   }
 * ];
 *
 * const onClickSubitem = (id) => {
 *   console.log(`Subitem clicked with id: ${id}`);
 * };
 *
 * const onClickItem = (id) => {
 *   console.log(`Item clicked with id: ${id}`);
 * };
 *
 * const idCurrentSubitem = 101;
 * const currentCountryId = 1;
 * const scrollTo = 102;
 *
 * return (
 *   <AccordionMenu
 *     items={items}
 *     onClickSubitem={onClickSubitem}
 *     onClickItem={onClickItem}
 *     idCurrentSubitem={idCurrentSubitem}
 *     currentCountryId={currentCountryId}
 *     scrollTo={scrollTo}
 *   />
 * );
 */
const AccordionMenu = ({
  items,
  onClickSubitem,
  onClickItem,
  idCurrentSubitem,
  currentCountryId,
  scrollTo,
}) => {
  const [current, setCurrent] = useState(idCurrentSubitem);

  const ref = useRef({});

  /**
   * @method useLayoutEffect
   * @memberof AccordionMenu
   *
   * @description When the page loads the page is scrolled to the specified subitem.
   */
  useLayoutEffect(() => {
    // running on timeout if they navigate directly to the link. First time doenst work with regular useEffect.
    if (scrollTo !== undefined && scrollTo !== -1 && !isNaN(scrollTo)) {
      const timer = setTimeout(() => {
        ref.current[scrollTo]?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }, 10);
        return () => {
            clearTimeout(timer);
        };
    }
  }, [scrollTo]);

  /**
   * @method useEffect
   * @memberof AccordionMenu
   *
   * @description When the value of 'scrollTo' changes the effect scrolls the page to the specified subitem.
   */
  useEffect(() => {
    if (scrollTo !== undefined && scrollTo !== -1 && !isNaN(scrollTo)) {
      const timer = setTimeout(() => {
        // timeout required for the mappping over and rendering of elements to start
        ref.current[scrollTo]?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }, 10);  
        return () => {
            clearTimeout(timer);
        };
    }
  }, [ref, scrollTo]);

  return (
    <div>
      {items.map((item) => {
        return (
          <div
            key={item.id}
            id={`menu-country-${item.id}`}
            ref={(el) => (ref.current[item.id] = el)}
          >
            {
              <AccordionMenuItem
                onClickSubitem={onClickSubitem}
                onClickItem={onClickItem}
                idCurrentSubitem={current}
                setCurrent={setCurrent}
                currentCountryId={currentCountryId}
                {...item}
              />
            }
          </div>
        );
      })}
    </div>
  );
};

AccordionMenu.propTypes = {
  onClickSubitem: PropTypes.func.isRequired,
  idCurrentSubitem: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
};

AccordionMenu.defaultProps = {
  onClickSubitem: (id) => {
    return id;
  },
  idCurrentSubitem: -1,
  items: [
    {
      id: -1,
      name: 'default',
      expanded: false,
      subitems: [
        {
          id: -1,
          name: 'default',
        },
      ],
    },
  ],
};

export default AccordionMenu;
