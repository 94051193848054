import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';


const initialState = {
  modules: {},
  groups: {},
  orderedList: [],
  loading: false,
  loadingSave: false,
  changesToSave: false,
  changesToApprove: false,
};

const fetchModulesListStart = (state, action) => {
  return updateObject(state, { loading: true, changesToApprove: false });
};

const fetchModulesListSuccess = (state, action) => {
  return updateObject(state, {
    modules: action.moduleList,
    groups: action.groupList,
    orderedList: action.orderedList,
    loading: false,
  });
};

const getAllProjectModulesSuccess = (state, action) => {
  return updateObject(state, {
    projectModules: action.projectModuleList,
    loading: false,
    error: '',
  });
};

const ClearModules = (state) => {
  return updateObject(state, { modules: {}, projectModules: {} });
};

const fetchModulesListFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateModuleSelection = (state, action) => {
  const updatedMod = updateObject(state.modules[action.id], {
    selected: action.checked,
  });
  return updateObject(state, {
    modules: { ...state.modules, [action.id]: updatedMod },
    changesToSave: true,
    changesToApprove: false,
  });
};

const batchUpdateModules = (state, action) => {
  let newState = { ...state, modules: { ...state.modules } };

  action.updates.forEach((update) => {
    newState.modules[update.moduleId] = {
      ...newState.modules[update.moduleId],
      selected: update.checked,
    };
  });

  return newState;
};

const updateGroupValidation = (state, action) => {
  const updatedGrp = updateObject(state.groups[action.id], {
    valid: action.valid,
  });
  return updateObject(state, {
    groups: { ...state.groups, [action.id]: updatedGrp },
  });
};

const updateMultipleModuleSelection = (state, action) => {
  const updatedMods = {};
  action.payload.modules.forEach((module) => {
    updatedMods[module.id] = updateObject(state.modules[module.id], {
      selected: module.checked,
    });
  });
  
  return updateObject(state, {
    modules: { ...state.modules, ...updatedMods },
    changesToSave: action.payload.changesToSave === false ? false : true,
    changesToApprove: false,
  });
};

const updateChangesToApprove = (state, action) => {
  return updateObject(state, { changesToApprove: action.approvalRdy });
};


const saveModuleSelectionStart = (state, action) => {
  return updateObject(state, { loadingSave: true });
};

const saveModuleSelectionFail = (state, action) => {
  return updateObject(state, { loadingSave: false });
};

const saveModuleSelectionSuccess = (state, action) => {
  return updateObject(state, {
    loadingSave: false,
    changesToSave: false,
    changesToApprove: true,
  });
};

const updatePageToggle = (state, action) => {
  return updateObject(state, {
    showQuestionDetails: action.showQuestionDetails,
  });
};

const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MODULES_LIST_START:
      return fetchModulesListStart(state, action);
    case actionTypes.FETCH_MODULES_LIST_SUCCESS:
      return fetchModulesListSuccess(state, action);
    case actionTypes.FETCH_MODULES_LIST_FAIL:
      return fetchModulesListFail(state, action);
    case actionTypes.UPDATE_MODULE_SELECTION:
      return updateModuleSelection(state, action);
    case actionTypes.BATCH_UPDATE_MODULES:
      return batchUpdateModules(state, action);
    case actionTypes.UPDATE_GROUP_VALIDATION:
      return updateGroupValidation(state, action);
    case actionTypes.UPDATE_MULTIPLE_MODULE_SELECTION:
      return updateMultipleModuleSelection(state, action);
    case actionTypes.UPDATE_CHANGES_TO_APPROVE:
      return updateChangesToApprove(state, action);
    case actionTypes.SAVE_MODULE_SELECTION_START:
      return saveModuleSelectionStart(state, action);
    case actionTypes.SAVE_MODULE_SELECTION_SUCCESS:
      return saveModuleSelectionSuccess(state, action);
    case actionTypes.SAVE_MODULE_SELECTION_FAIL:
      return saveModuleSelectionFail(state, action);
    case actionTypes.UPDATE_PAGE_TOGGLE:
      return updatePageToggle(state, action);
    case actionTypes.CLEAR_MODULES_DATA:
      return ClearModules(state);
    case actionTypes.GET_ALL_PROJECT_MODULES_SUCCESS:
      return getAllProjectModulesSuccess(state, action);
    default:
      return state;
  }
};

export default modulesReducer;
